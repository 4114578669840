import useAppStore from 'app/store/app.store'
import useAuthStore from 'app/store/auth.store'
import { STORAGE_KEYS } from 'app/utils/constants/storage.constants'

import Storage from '../Storage'

import Api from './index'

// interceptor for passing the locale and currency to the header of each request
export const headerInterceptors = (config) => {
  const { locale, currency } = useAppStore.getState()

  return { ...config, headers: { ...config.headers, locale, currency } }
}

// logout interceptor
export const logoutInterceptors = (error) => {
  const { status } = error.response

  const unauthorized = [401, 419]

  const store = useAuthStore.getState()

  if (unauthorized.includes(status) && store.token) {
    store.methods.resetStore()

    if (status === 419) {
      Storage.removeItem(STORAGE_KEYS.accessToken)
    } else {
      store.actions.logout()
    }

    if (Api.setAuthToken) {
      Api.setAuthToken()
    }
  }

  return Promise.reject(error)
}
