export const userKeys = {
  user: ['user'] as const,
  login: () => [...userKeys.user, 'login'],
  logout: () => [...userKeys.user, 'logout'],
  register: () => [...userKeys.user, 'register'],
  verification: () => [...userKeys.user, 'verification'],
  update: () => [...userKeys.user, 'update'],
  delete: () => [...userKeys.user, 'delete'],
  business: () => [...userKeys.user, 'business'],
  code: () => [...userKeys.user, 'code'],
  forgot: () => [...userKeys.user, 'forgot'],
  reset: () => [...userKeys.user, 'reset'],
  googleLogin: () => [...userKeys.user, 'googleLogin'],
  facebookLogin: () => [...userKeys.user, 'facebookLogin'],
  googleRegister: () => [...userKeys.user, 'googleRegister'],
  authorizeInApple: () => [...userKeys.user, 'authorizeInApple'],
  facebookRegister: () => [...userKeys.user, 'facebookRegister'],
  updateContactInfo: () => [...userKeys.user, 'updateContactInfo'],
  setHolidayMode: () => [...userKeys.user, 'setHolidayMode'],
  updatePassword: () => [...userKeys.user, 'updatePassword'],
  deleteUser: () => [...userKeys.user, 'deleteUser'],
  updateShippingAddress: () => [...userKeys.user, 'updateShippingAddress'],
  updateBusinessAddress: () => [...userKeys.user, 'updateBusinessAddress'],
  createShippingReturnRule: () => [
    ...userKeys.user,
    'createShippingReturnRule'
  ],
  updateShippingReturnRule: () => [
    ...userKeys.user,
    'updateShippingReturnRule'
  ],
  stripeAccountLink: () => [...userKeys.user, 'stripeAccountLink'],
  getUserBySlug: (slug) => [...userKeys.user, 'getUserBySlug', slug],
  getUserReviews: (params) => [...userKeys.user, 'getUserReviews', params],
  getShippingReturnRules: () => [...userKeys.user, 'getShippingReturnRules'],
  createMarketingContact: () => [...userKeys.user, 'createMarketingContact'],
  getVideos: () => [...userKeys.user, 'getVideos'],
  createReport: () => [...userKeys.user, 'createReport'],
  updateReviewReply: () => [...userKeys.user, 'updateReviewReply'],
  paypalAccountLink: () => [...userKeys.user, 'paypalAccountLink'],
  paypalPayment: () => [...userKeys.user, 'paypalPayment'],
  paypalShippingPayment: () => [...userKeys.user, 'paypalShippingPayment'],
  getCurrencies: () => [...userKeys.user, 'getCurrencies'],
  getStripeAccountId: (orderId: string) => [
    ...userKeys.user,
    'getStripeAccountId',
    orderId
  ],
  getPaypalClientToken: (orderId) => [
    ...userKeys.user,
    'getPaypalClientToken',
    orderId
  ],
  createPaypalAccountDetails: () => [
    ...userKeys.user,
    'createPaypalAccountDetails'
  ],
  getGuestToken: () => [...userKeys.user, 'getGuestToken'],
  updateGuestUserPassword: () => [...userKeys.user, 'updateGuestUserPassword'],
  sendMissingVehicle: () => [...userKeys.user, 'sendMissingVehicle'],
  getPaypalMerchantId: (orderId: string) => [
    ...userKeys.user,
    'getPaypalMerchantId',
    orderId
  ],
  getTimeFrames: () => [...userKeys.user, 'getTimeFrames'],
  createTimeFrame: () => [...userKeys.user, 'createTimeFrame'],
  deleteTimeFrame: () => [...userKeys.user, 'deleteTimeFrame']
}
