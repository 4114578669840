import BuyerVideoFirst from 'app/assets/videos/buyer-1.mp4'
import BuyerVideoSecond from 'app/assets/videos/buyer-2.mp4'
import BuyerVideoThird from 'app/assets/videos/buyer-3.mp4'
import SellerVideoFirst from 'app/assets/videos/seller-1.mp4'
import SellerVideoSecond from 'app/assets/videos/seller-2.mp4'
import SellerVideoThird from 'app/assets/videos/seller-3.mp4'
import { menuItems as siteMenuItems } from 'app/utils/constants/menu.constants'

export const LANDING_PATH = '/landing'

export const menuItems = [
  {
    text: 'About the App',
    anchor: '#aboutTheApp'
  },
  {
    text: 'For Buyer',
    anchor: '#forBuyer'
  },
  {
    text: 'For Seller',
    anchor: '#forSeller'
  },
  // TODO: Return menu link when the client adds videos
  // {
  //   text: 'Video',
  //   anchor: '#checkOurVideo'
  // },
  {
    text: 'Contact Us',
    anchor: siteMenuItems.contactUs.link
  }
]

export const advantages = [
  {
    title: 'Dedicated Auto Parts Marketplace',
    description:
      'Our app and website specialises in the trade of automotive parts. You can buy and sell Genuine, Aftermarket, new and or used parts on our platform. This means that we work differently to other online marketplaces – we’re dedicated to auto parts and auto parts only.'
  },
  {
    title: 'Smarter. Easier. Faster',
    description:
      'We’ve changed the way you trade car parts. No more calling, emailing, searching and time wasting. With our advanced search, intuitive explore page, and global connection, finding and selling auto parts instantly has never been easier.'
  },
  {
    title: 'Trade for Everyone',
    description:
      'Individual ? Business ? We are made for everyone. Whether you’re a private individual, a corporate business or someone in-between, you can use this platform to find, buy and sell your auto parts.'
  },
  {
    title: 'Save Money, Earn Money',
    description:
      'Anyone can sign up to become a seller and/or buyer. Turn a hobby into a business, make money from your garage, sell to an international audience. You have the opportunity to save and earn money at the same time!'
  }
]

export const buyerVideos = [
  { source: BuyerVideoFirst },
  { source: BuyerVideoSecond },
  { source: BuyerVideoThird }
]

export const sellerVideos = [
  { source: SellerVideoFirst },
  { source: SellerVideoSecond },
  { source: SellerVideoThird }
]

export enum CUSTOMER_TYPE {
  private = 'private',
  business = 'business'
}

export const customerType = [
  { label: 'Private', value: CUSTOMER_TYPE.private },
  { label: 'Business', value: CUSTOMER_TYPE.business }
]

export const businessOptions = [
  { label: 'Wrecker / Breaker', value: 'wrecker/breaker' },
  { label: 'Manufacturer', value: 'manufacturer' },
  { label: 'Distributor', value: 'distributor' },
  { label: 'Wholesaler', value: 'wholesaler' },
  { label: 'Retailer', value: 'retailer' },
  { label: 'Mechanic', value: 'mechanic' },
  { label: 'Self-Employed', value: 'self-employed' },
  { label: 'Investor', value: 'investor' }
]
