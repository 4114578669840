import { IS_BROWSER } from 'app/utils/constants/env.constants'
import { STORAGE_KEYS } from 'app/utils/constants/storage.constants'

import { storageKeys } from './Storage.constants'

type ItemName = typeof storageKeys[number]

class LocalStorage {
  static async getItem(itemName: ItemName) {
    if (!IS_BROWSER) return ''

    const item = localStorage.getItem(itemName)

    const numPatt = new RegExp(/^\d+$/)
    const jsonPatt = new RegExp(/[[{].*[}\]]/)

    if (item) {
      if (jsonPatt.test(item)) {
        return JSON.parse(item)
      }
      if (numPatt.test(item)) {
        return parseFloat(item)
      }

      return item
    }

    return null
  }

  static async setItem(itemName: ItemName, item: any) {
    if (!IS_BROWSER) return

    if (typeof item === 'object') {
      localStorage.setItem(itemName, JSON.stringify(item))
    } else {
      localStorage.setItem(itemName, item)
    }
  }

  static async removeItem(itemName: ItemName) {
    if (!IS_BROWSER) return

    localStorage.removeItem(itemName)
  }

  static async clear() {
    if (!IS_BROWSER) return

    localStorage.removeItem(STORAGE_KEYS.accessToken)
    localStorage.removeItem(STORAGE_KEYS.confirmationData)
  }
}

export default LocalStorage
