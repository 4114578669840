import { memo } from 'react'

import get from 'lodash.get'
import { FieldValues, Path, PathValue } from 'react-hook-form'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'
import { Subtract } from 'utility-types'

import { UseFormFieldProps } from 'app/hooks/useFormField'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'

export const getFormErrorByName = (
  errors: unknown,
  name: string
): string | null => get(errors, name)?.message || null

export interface TransformSchema {
  input: (string: any) => any
  output: (string: any) => any
}

export const getTransformHandler = (
  transform?: TransformSchema
): TransformSchema => {
  const defaultSchema = {
    input: (v: any) => v,
    output: (v: any) => v
  }

  return { ...defaultSchema, ...transform }
}

export const combineControllerProps = <T extends UseFormFieldProps>(
  props: T
) => {
  const keys = [
    'name',
    'rules',
    'shouldUnregister',
    'defaultValue',
    'control',
    'transform'
  ]

  const result = Object.entries(props).reduce(
    (acc, [key, value]) => {
      if (keys.includes(key)) acc.controllerProps[key] = value
      else acc[key] = value

      return acc
    },
    { controllerProps: {} }
  )

  return result as { controllerProps: UseFormFieldProps } & Subtract<
    T,
    UseFormFieldProps
  >
}

export const getAddressDefaultValues = (address?: ShippingAddresses[]) =>
  address?.map((item, index) => ({ ...item, uid: index }))

// Transform schemas
export const transformFieldInEmail: TransformSchema = {
  input: (value) => value?.toLowerCase() ?? '',
  output: (e) => e.target.value.toLowerCase() ?? ''
}

export const transformFieldInPositiveNumber: TransformSchema = {
  input: (value) => (Number.isNaN(value) || value === 0 ? 0 : Math.abs(value)),
  output: (e) => {
    const output = parseInt(e.target.value, 10)
    const value = Number.isNaN(output) ? 0 : output

    return Math.abs(value)
  }
}

export const formFieldMemo = (component) =>
  memo(component, (prevProps, nextProps) => prevProps.value === nextProps.value)

export const transformFieldNumberToString: TransformSchema = {
  input: (value = 0) => {
    if (value === null) return ''

    return String(value)
  },
  output: (e) => e
}

export const transformFieldPrice: TransformSchema = {
  input: (value) => {
    if (!!value) {
      return String(value).replace(/,/g, '.')
    }

    return value
  },
  output: (e) => e
}

export const transformFieldSwitch: TransformSchema = {
  input: (value) => {
    const checked = IS_NATIVE_APP ? !!value : value
    return checked
  },
  output: (e) => {
    const checked = IS_NATIVE_APP ? e : e.target.checked
    return checked ? 'yes' : null
  }
}

export const isEmptyValue = <T = any>(value: T) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export const omitEmptyValues = (values: Record<string, any>) =>
  Object.entries(values).reduce((acc, [key, value]) => {
    if (!isEmptyValue(value)) acc[key] = value

    return acc
  }, {})

export type ClearFieldValueIfNotFoundType<
  TValue,
  TDataForm extends FieldValues
> = {
  name: Path<TDataForm>
  value: TValue
  setValue: UseFormSetValue<TDataForm>
  data?: any[]
  findKey?: string
  findValue?: TValue
}

export const clearFieldValueIfNotFound = <
  TValue extends PathValue<TDataForm, Path<TDataForm>>,
  TDataForm extends FieldValues
>({
  name,
  value,
  setValue,
  data,
  findKey,
  findValue
}: ClearFieldValueIfNotFoundType<TValue, TDataForm>) => {
  if (!findValue || !data || !findKey) {
    setValue(name, value)

    return
  }

  const index = data?.findIndex((item) => item?.[findKey] === findValue)

  if (index === -1) {
    setValue(name, value)
  }
}
