import { WEBSITE_URL } from './env.constants'

export const PAYMENT_CONNECT_STATUS = {
  waiting_for_details: 'waiting_for_details',
  waiting_for_connect_completion: 'waiting_for_connect_completion',
  consent_granted: 'consent_granted',
  connect_completed: 'connect_completed'
}

export const PAYMENT_LINKS = {
  create_successful: `${WEBSITE_URL}/profile/details`,
  create_failed: `${WEBSITE_URL}/#failed`
}

export const PAYMENT_STATUS = {
  payment_succeeded: 'payment_succeeded'
}

export enum PAYMENT_METHOD {
  stripe = 'stripe',
  paypal = 'paypal'
}

export enum PAYPAL_PAYMENT_SOURCE {
  paypal = 'paypal',
  card = 'card'
}

export const PAYMENT_CARDS_STATUS = {
  possible: 'POSSIBLE',
  no: 'NO',
  unknown: 'UNKNOWN'
}

export const PAYPAL_PAYMENT_LINKS = {
  return_url: `${WEBSITE_URL}/profile/checkout/payment/paypal-processing`,
  cancel_url: `${WEBSITE_URL}/profile/checkout`
}
