import { API_URL } from 'app/utils/constants/env.constants'

import Axios from '../Axios'

const Api = new Axios({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

export default Api
