import isFunction from 'lodash/isFunction'

import DeserializerUtils from './deserializer-utils'

export default class Deserializer {
  opts: {}

  constructor(opts) {
    this.opts = opts || {}
  }

  deserialize(
    jsonapi,
    callback?: (err: Error | null, result: unknown) => void
  ) {
    const opts = this.opts

    function collection() {
      return Promise.all(
        jsonapi.data.map(function (d) {
          return new DeserializerUtils(jsonapi, d, opts).perform()
        })
      ).then(function (result) {
        if (isFunction(callback)) {
          callback(null, result)
        }

        return result
      })
    }

    function resource() {
      return new DeserializerUtils(jsonapi, jsonapi.data, opts)
        .perform()
        .then(function (result) {
          if (isFunction(callback)) {
            callback(null, result)
          }

          return result
        })
    }

    if (Array.isArray(jsonapi.data)) {
      return collection()
    } else {
      return resource()
    }
  }
}
