import dynamic from 'next/dynamic'
import Head from 'next/head'
import React from 'react'

import { CacheProvider, EmotionCache } from '@emotion/react'
import { DefaultSeo, NextSeoProps } from 'next-seo'
import { IntlShape, useIntl } from 'react-intl'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Hydrate } from 'react-query/hydration'
import type { SolitoAppProps } from 'solito'

import useCurrencyByLocation from 'app/hooks/useCurrencyByLocation'
import useWebViewAuthorize from 'app/hooks/useWebViewAuthorize'
import withAppProvider from 'app/providers/withAppProvider'
import Api from 'app/services/Api'
import {
  headerInterceptors,
  logoutInterceptors
} from 'app/services/Api/interceptors'
import AuthProvider from 'app/services/Auth/AuthProvider'
import useActionNotifications from 'app/services/Notification/hooks/useActionNotifications'
import useSubscribeCounterNotifications from 'app/services/Notification/hooks/useSubscribeCounterNotifications'
import { NextPage } from 'app/types/decalarations/next'
import {
  CI_ENV,
  IS_BROWSER,
  IS_RAF,
  WEBSITE_URL
} from 'app/utils/constants/env.constants'
import {
  deepMerge,
  verificationValuesDehydrated
} from 'app/utils/helpers/functions.helpers'

import Toaster from '~/components/base/Toaster'
import GTMScript from '~/containers/GTMScript'
import NextSeo from '~/containers/NextSeo'

import useShowMobileAppPopup from '~/hooks/useShowMobileAppPopup'
import useUserLocation from '~/hooks/useUserLocation'
import { createEmotionCache } from '~/utils/helpers/emotion.helpers'
import { GTM_ID } from '~/utils/helpers/gtag.helpers'

import TrackUserIdle from '~/providers/TrackUserIdle'

if (IS_BROWSER && !IS_RAF) {
  import('raf/polyfill')
}

const AuthGuard = dynamic(() => import('app/services/Auth/AuthGuard'))

const NewVersionNotification = dynamic(
  () => import('~/components/notifications/NewVersionNotification'),
  {
    ssr: false
  }
)

const PopupsWrapAcceptingInformation = dynamic(
  () =>
    import(
      '~/components/common/PopupsWrapAcceptingInformation/PopupsWrapAcceptingInformation'
    ),
  {
    ssr: false
  }
)

const SignInPopupContainer = dynamic(
  () => import('~/containers/SignInPopupContainer'),
  {
    ssr: false
  }
)
const OfflineNotification = dynamic(
  () => import('~/components/notifications/OfflineNotification'),
  { ssr: false }
)

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

Api.interceptors.request.use(headerInterceptors, (err) => Promise.reject(err))
Api.interceptors.response.use((r) => r, logoutInterceptors)

interface MyAppProps extends SolitoAppProps {
  emotionCache?: EmotionCache
  Component: SolitoAppProps['Component'] & Omit<NextPage, 'getLayout'>
  dehydratedState: Record<string, unknown>
  pageProps: { dehydratedState: Record<string, unknown> }
}

const MyApp = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
  dehydratedState
}: MyAppProps) => {
  const intl = useIntl()

  const getLayout = Component.getLayout || ((page) => page)
  const getMeta = Component.getMeta || ((meta: IntlShape) => meta)
  const { auth } = Component

  const meta = getMeta(intl) as NextSeoProps

  const hydrateState = deepMerge({
    a: dehydratedState,
    b: pageProps.dehydratedState,
    fn: verificationValuesDehydrated
  })
  useWebViewAuthorize()
  useUserLocation()
  useActionNotifications()
  useSubscribeCounterNotifications()
  useShowMobileAppPopup()
  useCurrencyByLocation()

  const isStaging = WEBSITE_URL?.includes('https://app-ifind.codicadev.net')
  const isLoadGTMScript = CI_ENV === 'production' && GTM_ID

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no user-scalable=0"
        />
      </Head>

      {isLoadGTMScript && <GTMScript />}

      <DefaultSeo
        titleTemplate="%s | IFnd"
        {...(isStaging && {
          dangerouslySetAllPagesToNoFollow: true,
          dangerouslySetAllPagesToNoIndex: true
        })}
      />

      {meta && <NextSeo {...meta} />}

      <Hydrate state={hydrateState}>
        <CacheProvider value={emotionCache}>
          <Toaster />

          <AuthProvider>
            <TrackUserIdle>
              {getLayout(
                <>
                  {!!auth?.guard && (
                    <AuthGuard {...auth}>
                      <Component />
                    </AuthGuard>
                  )}

                  {!auth?.guard && <Component />}
                </>
              )}
            </TrackUserIdle>
          </AuthProvider>

          <ReactQueryDevtools />
          <OfflineNotification />
          <NewVersionNotification />
          <SignInPopupContainer />
          <PopupsWrapAcceptingInformation />
        </CacheProvider>
      </Hydrate>
    </>
  )
}

export default withAppProvider(MyApp)
