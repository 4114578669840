import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import useOpenExternalLink from 'app/hooks/useOpenExternalLink'
import { IS_SAFARI } from 'app/utils/constants/env.constants'

const useShowMobileAppPopup = () => {
  const isShowedPopup = useRef(false)
  const router = useRouter()
  const { openLink } = useOpenExternalLink()

  const authToken = router?.query?.authToken as string
  const isNativeApp = router?.query?.isNativeApp as string

  const showMobileAppPopup = async () => {
    isShowedPopup.current = true

    const link = `ifndautoparts:/${router.asPath}`

    const isMobileBrowser = navigator.userAgent.includes('Mobile')

    if (isMobileBrowser && !IS_SAFARI) openLink({ link, target: '_self' })
  }

  useEffect(() => {
    if (!router.isReady) return

    const showPopup = !authToken && !isNativeApp && !isShowedPopup.current

    if (showPopup) showMobileAppPopup()
  }, [router.isReady, authToken])
}

export default useShowMobileAppPopup
