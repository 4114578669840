import { addReviewForUser } from 'app/services/Api/order'

export const ordersKeys = {
  orders: ['orders'] as const,
  getMyOrders: (params?: MyOrdersQueryParams) => [
    ...ordersKeys.orders,
    'getMyOrders',
    params
  ],
  getSoldItems: (params?: MyOrdersQueryParams) => [
    ...ordersKeys.orders,
    'getSoldItems',
    params
  ],
  getOrderById: (id) => [...ordersKeys.orders, 'getOrderById', id],
  getSoldItemById: (id) => [...ordersKeys.orders, 'getSoldItemById', id],
  addRateToSoldItem: () => [...ordersKeys.orders, 'addRateToSoldItem'],
  sendConfirmationCode: () => [...ordersKeys.orders, 'sendConfirmationCode'],
  addReviewForUser: () => [...ordersKeys.orders, 'addReviewForUser'],
  getInvoiceOrder: (id) => [...ordersKeys.orders, 'getInvoiceOrder', id]
}
