import { useEffect } from 'react'
import { AppState, AppStateEvent, AppStateStatus } from 'react-native'

type UseAppStateProps = {
  type: AppStateEvent
  callback: (status: AppStateStatus) => void
}

const useAppState = ({ type, callback }: UseAppStateProps) => {
  useEffect(() => {
    const subscription = AppState.addEventListener(type, callback)

    return () => subscription?.remove()
  }, [])
}

export default useAppState
