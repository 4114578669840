import { useRouter } from 'next/router'
import { useEffect } from 'react'

import Storage from 'app/services/Storage'
import { STORAGE_KEYS } from 'app/utils/constants/storage.constants'

import useCurrencyState from './useCurrencyState'

const useWebViewAuthorize = () => {
  const router = useRouter()
  const { changeCurrency } = useCurrencyState()

  const authToken = router?.query?.authToken as string
  const isNativeApp = router?.query?.isNativeApp as string
  const currency = router?.query?.currency as string

  useEffect(() => {
    if (authToken && isNativeApp) {
      Storage.setItem(STORAGE_KEYS.accessToken, authToken)
      changeCurrency(currency)
    }
  }, [authToken])
}

export default useWebViewAuthorize
