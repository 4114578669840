import React from 'react'

import { useGetChatNotifications } from 'app/services/Query/chat/chat.query'

import NotificationBadge from '../base/NotificationBadge'

interface ChatNotificationBadgeProps {
  dataTestId?: string
}

const ChatNotificationBadge = ({ dataTestId }: ChatNotificationBadgeProps) => {
  const { data: messages } = useGetChatNotifications()

  return (
    <NotificationBadge
      value={messages?.unread_messages}
      dataTestId={dataTestId}
    />
  )
}

export default ChatNotificationBadge
