export const defaultLocale = 'en-GB'
export const defaultCurrency = 'gbp'

export const locales = ['en-GB']

export const languages = {
  'en-GB': 'English'
}

export const defaultCurrencyItem = {
  gbp: '£'
}

export const languagesItems = Object.entries(languages).map(
  ([value, title]) => ({
    value,
    title
  })
)

export const defaultDateFormat = 'DD/MM/YYYY'
