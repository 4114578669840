import React from 'react'

import { useQueryClient } from 'react-query'
import { shallow } from 'zustand/shallow'

import useEffectAfterMount from 'app/hooks/useEffectAfterMount'
import useAppStore from 'app/store/app.store'

type CommonProviderProps = {}

const CommonProvider: RFC<CommonProviderProps> = ({ children }) => {
  const queryClient = useQueryClient()

  // invalidate active requests after changing locale or currency
  useEffectAfterMount(() => {
    const unsubStore = useAppStore.subscribe(
      (store) => ({ locale: store.locale, currency: store.currency }),
      () => queryClient.invalidateQueries({ active: true }),
      { equalityFn: shallow }
    )

    return () => unsubStore()
  }, [])

  return <>{children}</>
}

export default CommonProvider
