import LocalStorage from 'app/services/Storage'
import useAppStore from 'app/store/app.store'
import { STORAGE_KEYS } from 'app/utils/constants/storage.constants'

const useAcceptedCookies = () => {
  const { isAcceptedCookies, changeAcceptedCookies } = useAppStore((state) => ({
    isAcceptedCookies: state.isAcceptedCookies,
    changeAcceptedCookies: state.changeAcceptedCookies
  }))

  const setAcceptedCookies = (value: boolean) => {
    LocalStorage.setItem(STORAGE_KEYS.cookies, { cookies: value })
    changeAcceptedCookies(value)
  }

  return { isAcceptedCookies, setAcceptedCookies }
}

export default useAcceptedCookies
