import React from 'react'

import CommonProvider from './CommonProvider'
import IntlProvider from './IntlProvider'
import NavigationProvider from './NavigationProvider'
import ReactQueryProvider from './ReactQueryProvider'
import ThemeProvider from './ThemeProvider'

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <IntlProvider>
        <ReactQueryProvider>
          <ThemeProvider>
            <CommonProvider>
              <NavigationProvider>{children}</NavigationProvider>
            </CommonProvider>
          </ThemeProvider>
        </ReactQueryProvider>
      </IntlProvider>
    </>
  )
}

export default AppProvider
