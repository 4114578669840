import { useEffect } from 'react'

import { useQueryClient } from 'react-query'

import useAppState from 'app/hooks/native/useAppState'
import useInvalidateByChangeCount from 'app/hooks/useInvalidateByChangeCount'
import Actioncable from 'app/services/Actioncable'
import { chatKeys } from 'app/services/Query/chat/chat.keys'
import { notificationKeys } from 'app/services/Query/notification/notification.keys'
import useAuthStore, { getAuthUser } from 'app/store/auth.store'
import { IS_IOS_APP, IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import { hasGuestStatus } from 'app/utils/helpers/user.helpers'
import { hasChannelUnsubscribe } from 'app/utils/helpers/type.helpers'

let channel: unknown = null

const useSubscribeCounterNotifications = () => {
  const user = useAuthStore(getAuthUser)
  const isGuestUser = hasGuestStatus(user?.status)
  const isSubscribe = !!user?.id && !isGuestUser

  const queryClient = useQueryClient()
  const counterNotificationsKey = notificationKeys.getCounterNotifications()
  const chatNotificationsKey = chatKeys.getChatNotifications()

  const { invalidateByChangeCount } = useInvalidateByChangeCount()

  useAppState({
    type: 'change',
    callback: (status) => {
      if (IS_NATIVE_APP && channel && status === 'active') {
        queryClient.invalidateQueries(counterNotificationsKey)
      }
    }
  })

  const subscribeCounterNotifications = () => {
    channel = Actioncable.subscribe(
      { channel: 'CounterNotificationsChannel' },
      {
        received: async (data) => {
          const nextCounts = data.notifications_data

          invalidateByChangeCount({
            nextCounts
          })

          queryClient.setQueryData(counterNotificationsKey, nextCounts)
        }
      }
    ).then((c) => {
      channel = c
    })
  }

  useEffect(() => {
    if (isSubscribe && !channel) {
      if (IS_IOS_APP) {
        setTimeout(() => {
          subscribeCounterNotifications()
        }, 500)
      } else {
        subscribeCounterNotifications()
      }
    } else {
      queryClient.resetQueries(counterNotificationsKey)
      queryClient.resetQueries(chatNotificationsKey)
    }

    return (): void => {
      if (hasChannelUnsubscribe(channel)) {
        channel?.unsubscribe()
        channel = null
      }
    }
  }, [isSubscribe])
}

export default useSubscribeCounterNotifications
