import React from 'react'

import useUserNotifications from 'app/hooks/useUserNotifications'
import { TYPES_NOTIFICATION } from 'app/utils/constants/menu.constants'

import NotificationBadge from '../base/NotificationBadge'

type ProfileNotificationBadgeProps = {
  type: TYPES_NOTIFICATION
  dataTestId?: string
}

const ProfileNotificationBadge: RFC<ProfileNotificationBadgeProps> = ({
  type,
  dataTestId
}) => {
  const { notifications } = useUserNotifications()

  return (
    <NotificationBadge value={notifications[type]} dataTestId={dataTestId} />
  )
}

export default ProfileNotificationBadge
