import { ReactElement } from 'react'

import toast, { ToastOptions } from 'react-hot-toast'

import NotificationCard from './components/NotificationCard'

class Notification {
  static info({
    message,
    icon,
    options
  }: {
    message: string
    icon?: ReactElement
    options?: ToastOptions
  }): void {
    toast(
      (t) => (
        <NotificationCard
          message={message}
          icon={icon}
          onClose={() => toast.dismiss(t.id)}
        />
      ),
      {
        variant: 'notify',
        duration: 5000,
        style: {
          width: '343px',
          padding: '12px 6px',
          ...options?.style
        },
        ...options
      } as ToastOptions & ToastType
    )
  }
}

export default Notification
