import { useGetCounterNotifications } from 'app/services/Query/notification/notification.query'

const reviewKeys = ['buyerReviews', 'sellerReviews']

const useUserNotifications = () => {
  const { data = {} as CounterNotificationsModel } =
    useGetCounterNotifications()

  const notifications = {
    soldItems: data?.unread_sold_items || 0,
    offers: data?.unread_seller_offers || 0,
    auctions: data?.unread_auctions || 0,
    myOrders: data?.unread_order || 0,
    myOffers: data?.unread_buyer_offers || 0,
    myBids: data?.unread_bids || 0,
    buyerReviews: data?.unread_buyer_reviews || 0,
    sellerReviews: data?.unread_seller_reviews || 0,
    matchMe: data?.unread_match_me || 0
  }

  const notificationValues = Object.values(notifications).filter((e) => e)

  const reviewNotifications = reviewKeys.reduce(
    (acc, key) => notifications[key] + acc,
    0
  )

  const matchMeNotification = notifications.matchMe

  const isNotifications = !!notificationValues.length

  return {
    notifications,
    reviewNotifications,
    matchMeNotification,
    isNotifications
  }
}

export default useUserNotifications
