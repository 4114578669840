import { ReactElement } from 'react'

import CloseIcon from 'app/assets/icons/notification/close-icon.svg'
import IconNotification from 'app/assets/icons/notification/payment.svg'
import Box from 'app/components/base/Box'
import IconButton from 'app/components/base/IconButton'
import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'

type NotificationCardProps = {
  icon?: ReactElement
  message: string
  onClose: () => void
}

const NotificationCard: RFC<NotificationCardProps> = ({
  icon,
  message,
  onClose
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      width="100%"
      marginRight={2}
    >
      <Box sx={{ width: '40px', height: '40px' }}>
        {icon || <IconNotification />}
      </Box>

      <Text variant="body1" color="text.primary">
        {message}
      </Text>

      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          p: 1.25,
          right: 0,
          top: 0
        }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}

export default NotificationCard
