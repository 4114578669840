import Api from 'app/services/Api'

/**
 * get listing by slug
 */
export const getListing = (slug: string): Promise<ListingModel> =>
  Api.get(`/api/v1/listings/${slug}`, { deserialize: true })

/**
 * get listings by user
 */
export const getListingsByUser = (
  params: UserListingsParams
): Promise<PaginationListingModel> =>
  Api.get(`/api/v1/listings`, {
    params,
    deserialize: true
  })

/**
 * get listings price rang
 */
export const getListingsPriceRang = (): Promise<PriceRangModal> =>
  Api.get(`/api/v1/listings_price_range`)

/**
 * Get all slug for listing
 */
export const getAllSlugListingPages = (): Promise<{ slugs: string[] }> =>
  Api.get(`/api/v1/sitemaps/listing_slugs`)

/**
 * get user listing by slug
 */
export const getUserListing = (slug: string): Promise<ListingModel> =>
  Api.get(`/api/v1/users/listings/${slug}`, {
    deserialize: true
  })

/**
 * update user listing by slug
 */
export const updateListing = ({
  slug,
  data
}: {
  slug?: string
  data: Partial<ListingDTO>
}): Promise<ListingModel> =>
  Api.put(`/api/v1/users/listings/drafts/${slug}`, {
    data: {
      listing: data
    },
    deserialize: true
  })

/**
 * edit published listing
 */
export const editPublishedListing = ({
  slug,
  data
}: {
  slug?: string
  data: Partial<ListingDTO>
}): Promise<ListingModel> =>
  Api.put(`/api/v1/users/listings/published/${slug}`, {
    data: {
      listing: data
    },
    deserialize: true
  })

/**
 * update blocked listing
 */
export const updateBlockedListing = ({
  slug,
  data
}: {
  slug?: string
  data: Partial<ListingDTO>
}): Promise<ListingModel> =>
  Api.put(`/api/v1/users/listings/blocked/${slug}`, {
    data: {
      listing: data
    },
    deserialize: true
  })

/**
 * delete published listing by slug
 */
export const deleteUserListing = (slug: string): Promise<void> =>
  Api.del(`/api/v1/users/listings/published/${slug}`)

/**
 * deactivate published listing by slug
 */
export const deactivateUserListing = (slug: string): Promise<void> =>
  Api.put(`/api/v1/users/listings/deactivate/${slug}`)

/**
 * duplicate published listing by slug
 */
export const duplicateUserListing = (
  listing_slug: string
): Promise<ListingModel> =>
  Api.post(`/api/v1/users/listings/duplications`, {
    params: { listing_slug },
    deserialize: true
  })

/**
 * create user listing
 */
export const createListing = (
  data: Partial<ListingDTO>
): Promise<ListingModel> =>
  Api.post('/api/v1/users/listings/drafts', {
    data: {
      listing: data
    },
    deserialize: true
  })

/**
 * delete draft listing by slug
 */
export const deleteDraftListing = (slug: string): Promise<void> =>
  Api.del(`/api/v1/users/listings/drafts/${slug}`)

/**
 * get makes
 */
export const getMakes = (bySource?: string[]): Promise<Make[]> =>
  Api.get('/api/v1/makes', {
    ...(!!bySource && { params: { by_source: bySource } }),
    deserialize: true
  })

/**
 * get categories
 */
export const getCategories = (): Promise<Category[]> =>
  Api.get('/api/v1/categories', {
    deserialize: true
  })

/**
 * get models
 */
export const getModels = (params: {
  by_make_name: string[]
}): Promise<Model[]> =>
  Api.get(`/api/v1/models`, {
    params,
    deserialize: true
  })

/**
 * get sub-categories
 */
export const getSubCategories = (params): Promise<Category[]> =>
  Api.get(`/api/v1/sub_categories`, {
    params,
    deserialize: true
  })

/**
 * get attributions
 */
export const getAttributions = (params: {
  by_sub_category_id?: string
}): Promise<Attribution[]> =>
  Api.get(`/api/v1/attributions`, {
    params,
    deserialize: true
  })

/**
 * get attribution options
 */
export const getAttributionOptions = (params: {
  by_attribution_id?: number | string
}): Promise<AttributionOption[]> =>
  Api.get(`/api/v1/options`, {
    params,
    deserialize: true
  })

/**
 * publish users listings
 */
export const publishListing = (slug: string): Promise<ListingModel> =>
  Api.post(`/api/v1/users/listings/published`, {
    deserialize: true,
    params: {
      listing_slug: slug
    }
  })

/**
 * get users listings
 */
export const getUserListings = (params): Promise<PaginationListingModel> =>
  Api.get('/api/v1/users/listings', {
    params,
    deserialize: true
  })

/**
 * get users inventories
 */
export const getUserInventories = (
  params: InventoryQueryParams
): Promise<PaginationInventoryModel> =>
  Api.get('/api/v1/users/inventories/listings', {
    params,
    deserialize: true
  })

/**
 * update quantity listing by id
 */
export const updateQuantity = ({
  listingId,
  add_quantity,
  remove_quantity
}: StockControlDTO): Promise<void> =>
  Api.put(`/api/v1/users/inventories/listings/${listingId}`, {
    data: {
      stock_log: {
        add_quantity,
        remove_quantity
      }
    },
    deserialize: true
  })

/**
 * publish all draft users listings
 */
export const publishAllListings = (): Promise<ListingModel[]> =>
  Api.post('/api/v1/users/listings/publish_all', {
    deserialize: true
  })

/**
 * change reserve listings type
 */
export const changeReserveListingsType = ({
  actionType,
  orderId
}: ChangeReserveListingsTypeDTO): Promise<void> =>
  Api.put(`/api/v1/users/checkouts/reserve_listings/${orderId}`, {
    data: {
      action_type: actionType
    }
  })

/**
 * get you recently viewed listings
 */
export const getViewedListings = (
  params: ViewedListingsParams
): Promise<ListingModel[]> =>
  Api.get(`/api/v1/viewed_listings`, {
    params,
    deserialize: true
  })

/**
 * Get aws config data
 */
export const getAwsConfigData = (): Promise<UploadConfigData> =>
  Api.post(`/api/v1/users/listings/direct_upload_datas`)

/**
 * Upload to AWS
 */
export const uploadToAws = (params: UploadToAswDTO): Promise<string> =>
  Api.post(params.url, {
    baseURL: '',
    data: params.config,
    headers: {
      Authorization: '',
      'content-Type': 'multipart/form-data'
    },
    onUploadProgress: params.onUploadProgress
  })

/**
 * Bulk upload
 */
export const bulkUpload = (data: BulkUploadDTO): Promise<string> =>
  Api.post('/api/v1/users/listings/bulk_uploads', { data })

/**
 * get liked listings
 */
export const getLikedListings = (
  params: LikedListingsParams
): Promise<PaginationLikedListingsModel> =>
  Api.get(`/api/v1/users/likes`, {
    params,
    deserialize: true
  })

/**
 * create liked listing
 */
export const createLikedListing = (listingId: string): Promise<void> =>
  Api.post(`/api/v1/users/likes`, {
    data: {
      like: {
        listing_id: listingId
      }
    }
  })

/**
 * delete liked listing
 */
export const deleteLikedListing = (listingId: string): Promise<void> =>
  Api.del(`/api/v1/users/likes/${listingId}`)

/**
 * get liked listings by slug
 */
export const getLikedListingsBySlug = (
  listingSlugs: string[]
): Promise<LikedListingsBySlugModel> =>
  Api.get(`/api/v1/users/liked_listings`, {
    params: {
      listing_slugs: listingSlugs
    },
    deserialize: true
  })

/**
 *  get vin number
 */
export const getVinNumber = (params): Promise<{ vehicle: VinNumberModel }> =>
  Api.get(`/api/v1/vin_decoders`, {
    params,
    deserialize: true
  })

/**
 *  get cart listings
 */
export const getCartListings = (params): Promise<PaginationCartListingModel> =>
  Api.get(`/api/v1/users/carts/listings`, {
    params,
    deserialize: true
  })

/**
 *  get cart total converted price
 */
export const getCartTotalPrice = (): Promise<GetCartTotalPriceModel> =>
  Api.get(`/api/v1/users/carts/total_converted_price`)

/**
 *  add listings to cart
 */
export const addListingToCart = ({
  listingSlug,
  itemQuantity
}: AddCartListingDTO): Promise<void> =>
  Api.post(`/api/v1/users/carts/listings`, {
    data: {
      cart_listing: {
        listing_slug: listingSlug,
        item_quantity: itemQuantity
      }
    }
  })

/**
 *  update shipping type and quantity in cart listing
 */
export const updateCartListing = ({
  cartListingId,
  shippingType,
  itemQuantity
}: UpdateCartListingDTO): Promise<void> =>
  Api.put(`/api/v1/users/carts/listings/${cartListingId}`, {
    data: {
      cart_listing: {
        shipping_type: shippingType,
        item_quantity: itemQuantity
      }
    }
  })

/**
 *  delete listing from cart
 */
export const deleteListingFromCart = (cartListingId: number): Promise<void> =>
  Api.del(`/api/v1/users/carts/listings/${cartListingId}`)

/**
 *  get cart listings count
 */
export const getCartListingsCount = (): Promise<{
  cart_listings_count: number
}> => Api.get(`/api/v1/users/carts/listings_counter`)

/**
 *  generate listing label
 */
export const generateListingLabel = (
  slug: string
): Promise<InventoryFileModel> =>
  Api.post(`/api/v1/users/listings/${slug}/label`, {
    deserialize: true
  })

/**
 *  inventory file export
 */
export const inventoryFileExport = (
  params: InventoryQueryParams
): Promise<InventoryFileModel> =>
  Api.post(`/api/v1/users/inventories/exports`, {
    params,
    deserialize: true
  })

/**
 *  validate cart listings
 */
export const validateCartListings = (): Promise<void> =>
  Api.post(`/api/v1/users/carts/validate_listings`)

/**
 *  get vehicle generations
 */

export const getVehicleGenerations = (
  modelId: ListingCarAttributeId[]
): Promise<ListingGenerationModel[]> =>
  Api.get(`/api/v1/generations`, {
    params: { by_model_id: modelId },
    deserialize: true
  })

/**
 *  get vehicle series
 */
export const getVehicleSeries = ({
  model_id,
  byGenerationId
}: {
  model_id?: string | number
  byGenerationId?: ListingCarAttributeId[]
}): Promise<ListingSeriesModel[]> =>
  Api.get(`/api/v1/series`, {
    params: { model_id, by_generation_id: byGenerationId },
    deserialize: true
  })

/**
 *  get vehicle trims
 */
export const getVehicleTrims = ({
  bySerieId
}: {
  bySerieId: ListingCarAttributeId[]
}): Promise<ListingTrimModel[]> =>
  Api.get(`/api/v1/trims`, {
    params: { by_serie_id: bySerieId },
    deserialize: true
  })

/**
 *  get vehicle universal params
 */
export const getVehicleUniversalParams =
  (): Promise<VehicleUniversalParamsModel> =>
    Api.get(`/api/v1/universal_vehicle_details`, { deserialize: true })

/**
 * get trade price all listings
 */
export const getTradePriceAllListings = (): Promise<TradePriceModel> =>
  Api.get(`/api/v1/users/trade_pricing`, {
    deserialize: true
  })

/**
 * update trade price all listings
 */
export const updateTradePriceAllListings = (
  data: TradePriceFV
): Promise<void> =>
  Api.put(`/api/v1/users/trade_pricing`, {
    deserialize: true,
    data: {
      trade_pricing: {
        rate: data.trade_pricing
      }
    }
  })

/**
 * deactivate trade price all listings
 */
export const deactivateTradePriceAllListings = (): Promise<void> =>
  Api.del(`/api/v1/users/trade_pricing`, {
    deserialize: true
  })

/**
 * get similar listings
 */
export const getSimilarListings = (
  listingId: string
): Promise<SimilarListingsModel> =>
  Api.get(`api/v1/listings/${listingId}/similar`, { deserialize: true })
