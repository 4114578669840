import React, { Ref, forwardRef, memo } from 'react'

import { Stack as MuiStack, StackProps as MuiStackProps } from '@mui/material'

export type StackProps = MuiStackProps

const Stack = (
  { children, ...props }: StackProps,
  ref: Ref<HTMLDivElement>
) => {
  return (
    <MuiStack ref={ref} {...props}>
      {children}
    </MuiStack>
  )
}

export default memo(forwardRef(Stack))
