import dynamic from 'next/dynamic'
import { useEffect } from 'react'

import Actioncable from 'app/services/Actioncable'
import Notification from 'app/services/Notification'
import useAuthStore, { getAuthUserId } from 'app/store/auth.store'
import { hasChannelUnsubscribe } from 'app/utils/helpers/type.helpers'

const ApprovedAccountIcon = dynamic(
  () => import('app/assets/icons/notification/approved-account.svg'),
  {
    ssr: false
  }
)
const AuctionIcon = dynamic(
  () => import('app/assets/icons/notification/auction.svg'),
  {
    ssr: false
  }
)
const ChatMessageIcon = dynamic(
  () => import('app/assets/icons/notification/new-message.svg'),
  {
    ssr: false
  }
)
const OfferIcon = dynamic(
  () => import('app/assets/icons/notification/offer.svg'),
  {
    ssr: false
  }
)
const OrderIcon = dynamic(
  () => import('app/assets/icons/notification/order.svg'),
  {
    ssr: false
  }
)
const RefundRequestIcon = dynamic(
  () => import('app/assets/icons/notification/refund-request.svg'),
  {
    ssr: false
  }
)
const ReviewIcon = dynamic(
  () => import('app/assets/icons/notification/review.svg'),
  {
    ssr: false
  }
)
const SoldListingIcon = dynamic(
  () => import('app/assets/icons/notification/sold-listing.svg'),
  {
    ssr: false
  }
)
const MatchMeIcon = dynamic(
  () => import('app/assets/icons/notification/match-me.svg'),
  {
    ssr: false
  }
)

const notificationsIcon = {
  accepted_offer: <OfferIcon />,
  auction: <AuctionIcon />,
  approved_account: <ApprovedAccountIcon />,
  auction_close_with_winner: <AuctionIcon />,
  chat_message: <ChatMessageIcon />,
  create_offer: <OfferIcon />,
  create_bid: <AuctionIcon />,
  reserve_lowered: <AuctionIcon />,
  create_review: <ReviewIcon />,
  create_refund_request: <RefundRequestIcon />,
  counter_offer: <OfferIcon />,
  declined_refund_request: <RefundRequestIcon />,
  offer: <OfferIcon />,
  order: <OrderIcon />,
  refund_request: <RefundRequestIcon />,
  review: <ReviewIcon />,
  sold_listing: <SoldListingIcon />,
  buyer_listing_matched: <MatchMeIcon />
}

const useActionNotifications = () => {
  const userId = useAuthStore(getAuthUserId)

  useEffect(() => {
    let channel: unknown = null

    if (userId) {
      Actioncable.subscribe(
        { channel: 'ActionsNotificationsChannel' },
        {
          received: (data) => {
            if (data.message && data.object_name) {
              Notification.info({
                message: data.message,
                icon: notificationsIcon[data.object_name]
              })
            }
          }
        }
      ).then((c) => {
        channel = c
      })
    }

    return (): void => {
      if (hasChannelUnsubscribe(channel)) {
        channel?.unsubscribe()
      }
    }
  }, [userId])
}
export default useActionNotifications
