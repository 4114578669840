import { useRouter } from 'next/router'
import React from 'react'

import { NextSeo as Seo, NextSeoProps } from 'next-seo'

import { WEBSITE_URL } from 'app/utils/constants/env.constants'

const NextSeo = (props: NextSeoProps) => {
  const { asPath } = useRouter()

  const canonicalPath = props.canonical ? '/' + props.canonical : asPath
  const canonical = `${WEBSITE_URL}${canonicalPath}`

  return (
    <Seo
      {...props}
      title={props.title}
      description={props.description}
      openGraph={{
        title: props.title,
        description: props.description,
        ...(props.openGraph || {})
      }}
      canonical={canonical}
    />
  )
}

export default NextSeo
