import { ErrorHandlerType } from 'app/services/ErrorHandler'

export const isOfType = {
  null(x: any): x is null {
    return x === null
  },
  undefined(x: any): x is undefined {
    return x === undefined
  },
  nil(x: any): x is null | undefined {
    return this.null(x) || this.undefined(x)
  },
  string(x: any): x is string {
    return !this.nil(x) && (typeof x === 'string' || x instanceof String)
  },
  number(x: any): x is number {
    return (
      !this.nil(x) &&
      ((!Number.isNaN(x) && Number.isFinite(x) && typeof x === 'number') ||
        x instanceof Number)
    )
  },
  boolean(x: any): x is boolean {
    return !this.nil(x) && (typeof x === 'boolean' || x instanceof Boolean)
  },
  array(x: any): x is any[] {
    return !this.nil(x) && Array.isArray(x)
  },
  object(x: any): x is Record<string, any> {
    return {}.toString.call(x) === '[object Object]'
  },
  type(x, X) {
    return !this.nil(x) && x instanceof X
  },
  set(x) {
    return this.type(x, Set)
  },
  map(x) {
    return this.type(x, Map)
  },
  date(x) {
    return this.type(x, Date)
  }
}

export const hasChannelUnsubscribe = (
  channel: unknown
): channel is { unsubscribe: () => void } =>
  !!channel &&
  isOfType.object(channel) &&
  'unsubscribe' in channel &&
  typeof channel.unsubscribe === 'function'

export const hasStatusError = (error: unknown): error is { status: number } =>
  !!error && isOfType.object(error) && 'status' in error

export const isPaymentError = (
  error: unknown
): error is { data: ErrorHandlerType & { type: string } } =>
  !!error && isOfType.object(error) && 'data' in error && 'type' in error.data
