import React from 'react'

import AppProvider from './AppProvider'

const withAppProvider = (Component) => (props) => {
  return (
    <AppProvider>
      <Component {...props} />
    </AppProvider>
  )
}

export default withAppProvider
