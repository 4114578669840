import { Platform } from 'react-native'

import jwtDecode from 'jwt-decode'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import Actioncable from 'app/services/Actioncable'
import Api from 'app/services/Api'
import {
  createNotificationToken,
  extendUserSession,
  getUser as getUserData,
  removeNotificationToken,
  signInEmail,
  signInPhoneNumber,
  signUpEmail,
  signUpPhoneNumber
} from 'app/services/Api/user'
import Storage from 'app/services/Storage'
import { IS_BROWSER, IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import { RECEIVED_PRICE_PERCENT } from 'app/utils/constants/listing.constants'
import { USER_ROLE } from 'app/utils/constants/role.constants'
import { STORAGE_KEYS } from 'app/utils/constants/storage.constants'
import {
  getActivePaymentGateway,
  getIsWaitConnectPaypal
} from 'app/utils/helpers/functions.helpers'
import { hasGuestStatus } from 'app/utils/helpers/user.helpers'

type UserLoginType = 'email' | 'phone'

export interface AuthStore {
  user: UserModel
  token: string | null | undefined | false
  notificationToken: string | null
  roles: {
    personal: 'personal'
    business: 'business'
  }
  state: {
    initialized: boolean
    isAuthenticated: boolean
    isConfirmed: boolean
    isLogged: boolean
    error: unknown
    isLoading: boolean
    isIdle: boolean
    refetch: () => void
    isFetched: boolean
  }
  methods: {
    clearUser: () => void
    updateUser: (data: UserModel) => void
    setToken: (token: string) => Promise<void>
    getToken: () => Promise<string | undefined>
    getTokenData: (accessToken?: string) => Promise<UserModel>
    resetStore: () => void
    updateStoreState: (state: Partial<AuthStore['state']>) => void
    setNotificationToken: (token: string | null) => void
  }
  actions: {
    authorize: (token?: string) => void
    loadUser: () => Promise<UserModel>
    login: (data: SignInDTO, type: UserLoginType) => Promise<UserModel>
    register: (data: SignUpDTO, type: UserLoginType) => Promise<UserModel>
    logout: () => void
    updateSession: () => Promise<JWT>
  }
  selectors: {
    isConfirmed: () => boolean
    isLogged: () => boolean
    getRole: () => string | undefined
    hasRole: (role: string) => boolean
  }
}

const defaultState: AuthStore['state'] = {
  initialized: false,
  isAuthenticated: false,
  isConfirmed: false,
  isLogged: false,
  error: null,
  isLoading: false,
  isIdle: true,
  refetch: () => {},
  isFetched: false
}

const useAuthStore = createWithEqualityFn<AuthStore>((set, get) => {
  const isWeb = Platform.OS === 'web'
  const accessToken =
    isWeb && IS_BROWSER && localStorage.getItem(STORAGE_KEYS.accessToken)

  return {
    token: accessToken,
    notificationToken: null,
    user: {} as UserModel,
    state: defaultState,
    methods: {
      getToken: async () => {
        return Storage.getItem(STORAGE_KEYS.accessToken)
      },
      async setToken(token) {
        return Storage.setItem(STORAGE_KEYS.accessToken, token)
      },
      async getTokenData(accessToken?: string) {
        const token = await this.getToken()

        try {
          const data = jwtDecode<any>(accessToken || token || '') || {}

          /**
           * In mobile app we always keep logged in users
           */
          const tokenData = {
            ...data,
            ...(IS_NATIVE_APP && { keep_me_logged_in: true })
          }

          return tokenData
        } catch (err) {
          return {}
        }
      },
      updateUser: (data: UserModel) =>
        set((state) => {
          return {
            ...state,
            user: data,
            state: {
              ...state.state,
              isAuthenticated: !!data,
              isConfirmed: data?.confirmed,
              isLoading: false,
              isLogged: !!data?.id,
              initialized: true
            }
          }
        }),
      clearUser: () => set(() => ({ user: {} as UserModel })),
      resetStore: () =>
        set(() => ({
          token: null,
          user: {} as UserModel,
          state: defaultState
        })),
      updateStoreState: (newState) =>
        set((state) => ({ ...state, state: { ...state.state, ...newState } })),
      setNotificationToken: (token) => set(() => ({ notificationToken: token }))
    },
    actions: {
      authorize: async (token?: string) => {
        const actualToken = token || (await get().methods.getToken())
        const notificationToken = get().notificationToken

        if (actualToken) await get().methods.setToken(actualToken)

        if (!actualToken) return null

        const tokenData = await get().methods.getTokenData()
        const isGuestUser = hasGuestStatus(tokenData?.status)

        await Api.setAuthToken(actualToken)

        if (IS_NATIVE_APP && notificationToken) {
          await createNotificationToken(notificationToken)
        }

        if (!isGuestUser) {
          if (!!token) Actioncable.disconnectConsumer()

          const data = await get().actions.loadUser()

          get().methods.updateUser({ ...tokenData, ...data })
        } else {
          get().methods.updateUser({ ...tokenData })
        }

        set((state) => ({
          ...state,
          token: actualToken
        }))
      },
      async loadUser() {
        get().methods.updateStoreState({ isLoading: true })

        const resp = await getUserData()

        return { ...resp }
      },
      async login(data: SignInDTO, type) {
        let jwt = ''

        if (type === 'email') {
          const resp = await signInEmail(data)

          jwt = resp.jwt_token
        } else {
          const resp = await signInPhoneNumber(data)

          jwt = resp.jwt_token
        }

        await this.authorize(jwt)

        return get().user
      },
      async register(data: SignUpDTO, type) {
        let jwt = ''
        if (type === 'phone') {
          const resp = await signUpPhoneNumber(data)

          jwt = resp.jwt_token
        } else {
          const resp = await signUpEmail(data)

          jwt = resp.jwt_token
        }

        await this.authorize(jwt)

        return get().user
      },
      logout: () => {
        const notificationToken = get().notificationToken

        if (IS_NATIVE_APP && notificationToken) {
          removeNotificationToken(notificationToken)
        }

        get().methods.clearUser()
        get().methods.resetStore()

        Storage.removeItem(STORAGE_KEYS.accessToken)
        Api.setAuthToken(undefined)
        Actioncable.disconnectConsumer()
      },
      updateSession: async () => {
        const newToken = await extendUserSession()

        if (newToken) {
          get().actions.authorize(newToken.jwt_token)
        }

        return newToken
      }
    },
    selectors: {
      isConfirmed: () => !!get().user?.confirmed,
      isLogged: () => !!get().user?.confirmed,
      getRole: () => get().user?.role,
      hasRole: (role) => role === get().user?.role
    },
    roles: {
      personal: USER_ROLE.PERSONAL,
      business: USER_ROLE.BUSINESS
    }
  }
}, shallow)

export default useAuthStore

// auth selectors
export const getAuthUser = (store: AuthStore) => store.user
export const getAuthUserAddresses = (store: AuthStore) => store.user.addresses
export const getAuthUserId = (store: AuthStore) => store.user.id
export const getAuthUserSlug = (store: AuthStore) => store.user.slug
export const getAuthUserRole = (store: AuthStore) => store.user.role
export const getAuthToken = (store: AuthStore) => store.token
export const getAuthState = (store: AuthStore) => store.state
export const getAuthRoles = (store: AuthStore) => store.roles
export const getAuthMethods = (store: AuthStore) => store.methods
export const getAuthActions = (store: AuthStore) => store.actions
export const getAuthSelectors = (store: AuthStore) => store.selectors
export const getAuthorizeAction = (store: AuthStore) => store.actions.authorize
export const hasLicense = (store: AuthStore): boolean =>
  store.user.documents_uploaded ||
  store.user.role === 'personal' ||
  !store.user.confirmed ||
  !store.user.id

// user selectors
export const getUser = (store: AuthStore) => store.user
export const getCurrentMerchantFee = (store: AuthStore) =>
  store.user.current_merchant_fee_in_percentages || RECEIVED_PRICE_PERCENT
export const getUserEmail = (store: AuthStore) => store.user.email
export const getUserToken = (store: AuthStore) => store.token
export const getIsConfirmed = (store: AuthStore) => store.state.isConfirmed
export const getIsLogged = (store: AuthStore) => store.state.isLogged
export const getIsAuthenticated = (store: AuthStore) =>
  store.state.isAuthenticated
export const getIsInitialized = (store: AuthStore) => store.state.initialized
export const getIsAuthLoading = (store: AuthStore) => store.state.isLoading
export const getPaymentGateways = (store: AuthStore) =>
  store.user.payment_gateways
export const getActivePayment = (store: AuthStore) =>
  getActivePaymentGateway(store.user.payment_gateways || [])
export const getWaitConnectPaypal = (store: AuthStore) =>
  getIsWaitConnectPaypal(store.user.payment_gateways || [])
