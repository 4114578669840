import React from 'react'

import { Typography, TypographyProps } from '@mui/material'
import { Breakpoint } from '@mui/material/styles'

import typography from 'app/theme/mui/typography'

export type TextProps = TypographyProps & {
  component?: React.ElementType
  size?: Partial<Record<Breakpoint, TypographyProps['variant']>>
  lineClamp?: number
}

const generateStyles = (size: TextProps['size']) => {
  const genStyles = Object.entries(size || {}).reduce(
    (acc, [key, value]) => {
      let newAcc = acc

      newAcc = {
        fontSize: {
          ...newAcc.fontSize,
          [key]: typography[value ?? 'body1'].fontSize
        },
        lineHeight: {
          ...newAcc.lineHeight,
          [key]: typography[value ?? 'body1'].lineHeight
        },
        fontFamily: {
          ...newAcc.fontFamily,
          [key]: typography[value].fontFamily
        }
      }

      return newAcc
    },
    { fontSize: {}, lineHeight: {}, fontFamily: {} }
  )

  return genStyles
}

const Text: RFC<TextProps> = ({ size, children, lineClamp, sx, ...rest }) => {
  const styles = generateStyles(size)

  return (
    <Typography
      sx={{
        ...styles,
        ...(lineClamp && {
          display: '-webkit-box',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          WebkitLineClamp: lineClamp,
          '-webkit-box-orient': 'vertical',
          wordBreak: 'break-all'
        }),
        ...sx
      }}
      {...rest}
    >
      {children}
    </Typography>
  )
}

export default Text
