const fonts = {
  nunito: {
    name: 'Nunito',
    weight: {
      bold: 'Nunito-bold',
      default: 'Nunito',
      normal: 'Nunito',
      200: 'Nunito',
      300: 'Nunito',
      400: 'Nunito',
      500: 'Nunito-medium',
      600: 'Nunito-semiBold',
      700: 'Nunito-bold',
      800: 'Nunito-bold',
      900: 'Nunito-bold'
    }
  },
  poppins: {
    name: 'Poppins',
    weight: {
      bold: 'Poppins-bold',
      default: 'Poppins',
      normal: 'Poppins',
      200: 'Poppins-light',
      300: 'Poppins-light',
      400: 'Poppins',
      500: 'Poppins-medium',
      600: 'Poppins-medium',
      700: 'Poppins-bold',
      800: 'Poppins-bold',
      900: 'Poppins-bold'
    }
  }
} as const

export type Fonts = typeof fonts

export default fonts
