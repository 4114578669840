import { WEBSOCKET_CABLE_URL } from 'app/utils/constants/env.constants'
import { STORAGE_KEYS } from 'app/utils/constants/storage.constants'

import Storage from '../Storage'

class ActioncableService {
  private url = ''
  cable: ActionCable.Cable | null = null

  constructor(url) {
    this.url = url
  }

  createConsumer = async (token: string) => {
    try {
      const actioncable = // @ts-ignore
        (await import('app/services/Actioncable/lib/Actioncable')).default

      this.cable = actioncable.createConsumer(`${this.url}?token=${token}`)
    } catch (error) {
      console.error(error)
    }
  }

  updateConsumer = async (token: string) => {
    try {
      await this.createConsumer(token)

      this.cable?.ensureActiveConnection()
    } catch (error) {
      console.error(error)
    }
  }

  disconnectConsumer = () => {
    try {
      this.cable?.disconnect()

      this.cable = null
    } catch (error) {
      console.error(error)
    }
  }

  subscribe = async (
    params: ActionCable.ChannelNameWithParams,
    options: unknown
  ) => {
    const token = await Storage.getItem(STORAGE_KEYS.accessToken)

    if (!this.cable && token) {
      await this.createConsumer(token)
    }

    return this.cable?.subscriptions?.create(
      params,
      options as ActionCable.CreateMixin & ThisType<ActionCable.Channel>
    )
  }
}

const Actioncable = new ActioncableService(WEBSOCKET_CABLE_URL)

export default Actioncable
