import type { ReactElement } from 'react'
import { Platform } from 'react-native'

import { defineMessages, MessageDescriptor } from 'react-intl'

import BagIcon from 'app/assets/icons/profile/menu/bag.svg'
import BoxIcon from 'app/assets/icons/profile/menu/box.svg'
import CarIcon from 'app/assets/icons/profile/menu/car.svg'
import CouponIcon from 'app/assets/icons/profile/menu/coupon.svg'
import ListingIcon from 'app/assets/icons/profile/menu/listing.svg'
import MessageIcon from 'app/assets/icons/profile/menu/message.svg'
import OfferIcon from 'app/assets/icons/profile/menu/offer.svg'
import SearchIcon from 'app/assets/icons/profile/menu/search.svg'
import UserIcon from 'app/assets/icons/profile/menu/user.svg'
import WatchlistIcon from 'app/assets/icons/profile/menu/watchlist.svg'
import ChatNotificationBadge from 'app/components/common/ChatNotificationBadge'
import ProfileNotificationBadge from 'app/components/common/ProfileNotificationBadge'
import { getCategories } from 'app/services/Api/listing'
import { getAllStaticPages } from 'app/services/Api/staticPage'
import { USER_ROLE } from 'app/utils/constants/role.constants'

import { WEBSITE_URL } from './env.constants'

const isWeb = Platform.OS === 'web'

export const messages = defineMessages({
  carParts: { defaultMessage: 'Car Parts' },
  selling: { defaultMessage: 'Selling' },
  contactUs: { defaultMessage: 'Contact Us' },
  youtube: { defaultMessage: 'YouTube' },
  profileDetails: { defaultMessage: 'Profile Details' },
  messages: { defaultMessage: 'Messages' },
  myGarage: { defaultMessage: 'My Garage' },
  myListings: { defaultMessage: 'My Listings' },
  soldItems: { defaultMessage: 'Sold Items' },
  offers: { defaultMessage: 'Offers' },
  auctions: { defaultMessage: 'Auctions' },
  shipping: { defaultMessage: 'Shipping & Return Rules' },
  myOrders: { defaultMessage: 'My Orders' },
  myOffers: { defaultMessage: 'My Offers' },
  myBids: { defaultMessage: 'My Bids' },
  auctionsWatchlist: { defaultMessage: 'Auctions Watchlist' },
  matchSearch: { defaultMessage: 'Match Me & Saved Search' },
  profile: { defaultMessage: 'My ifnd Profile' },
  companySupport: { defaultMessage: 'Company & Support' },
  blog: { defaultMessage: 'Blog' },
  helpCenter: { defaultMessage: 'Help Center' },
  inventory: { defaultMessage: 'Inventory' }
})

export enum TYPES_NOTIFICATION {
  messages = 'messages',
  soldItems = 'soldItems',
  offers = 'offers',
  auctions = 'auctions',
  myOrders = 'myOrders',
  myOffers = 'myOffers',
  myBids = 'myBids',
  buyerReviews = 'buyerReviews',
  sellerReviews = 'sellerReviews',
  matchMe = 'matchMe'
}

export type MenuItem = {
  title: string | MessageDescriptor | ReactElement
  link?: string
  items?: MenuItem[]
  loadItems?: () => void
  value?: unknown
  onChange?: (item: MenuItem) => unknown
  badge?: ReactElement | null
  icon?: ReactElement | null
  role?: USER_ROLE
}

export const menuItems = {
  contactUs: { title: messages.contactUs, link: '/contact-us' },
  profileDetails: {
    title: messages.profileDetails,
    link: '/profile/details',
    icon: <UserIcon />
  },
  messages: {
    title: messages.messages,
    link: '/profile/messages',
    badge: <ChatNotificationBadge dataTestId="chat-notification-badge" />,
    icon: <MessageIcon />
  },
  myGarage: {
    title: messages.myGarage,
    link: '/profile/my-garage',
    icon: <CarIcon />
  },
  myListings: {
    title: messages.myListings,
    link: '/profile/my-listings/available',
    icon: <ListingIcon />,
    role: USER_ROLE.PERSONAL
  },
  soldItems: {
    title: messages.soldItems,
    link: '/profile/sold-items/orders',
    badge: (
      <ProfileNotificationBadge
        dataTestId="sold-items-badge"
        type={TYPES_NOTIFICATION.soldItems}
      />
    ),
    icon: <BagIcon />
  },
  offers: {
    title: messages.offers,
    link: '/profile/offers',
    badge: (
      <ProfileNotificationBadge
        dataTestId="offers-badge"
        type={TYPES_NOTIFICATION.offers}
      />
    ),
    icon: <OfferIcon />
  },
  auctions: {
    title: messages.auctions,
    link: '/profile/auctions',
    badge: (
      <ProfileNotificationBadge
        dataTestId="auctions-badge"
        type={TYPES_NOTIFICATION.auctions}
      />
    ),
    icon: <CouponIcon />
  },
  shipping: {
    title: messages.shipping,
    link: '/profile/shipping-return-rules',
    icon: <BoxIcon />
  },
  myOrders: {
    title: messages.myOrders,
    link: '/profile/my-orders/orders',
    badge: (
      <ProfileNotificationBadge
        dataTestId="my-orders-badge"
        type={TYPES_NOTIFICATION.myOrders}
      />
    ),
    icon: <BagIcon />
  },
  myOffers: {
    title: messages.myOffers,
    link: '/profile/my-offers',
    badge: (
      <ProfileNotificationBadge
        dataTestId="my-offers-badge"
        type={TYPES_NOTIFICATION.myOffers}
      />
    ),
    icon: <OfferIcon />
  },
  myBids: {
    title: messages.myBids,
    link: '/profile/my-bids',
    badge: (
      <ProfileNotificationBadge
        dataTestId="my-bids-badge"
        type={TYPES_NOTIFICATION.myBids}
      />
    ),
    icon: <CouponIcon />
  },
  auctionsWatchlist: {
    title: messages.auctionsWatchlist,
    link: '/profile/my-bids/watchlist',
    icon: <WatchlistIcon />
  },
  matchSearch: {
    title: messages.matchSearch,
    link: '/profile/match-me-and-saved-search/match',
    badge: (
      <ProfileNotificationBadge
        dataTestId="match-search-badge"
        type={TYPES_NOTIFICATION.matchMe}
      />
    ),
    icon: <SearchIcon />
  },
  profile: { title: messages.profile, link: '/profile' },
  youtube: { title: messages.youtube, link: '/youtube' },
  companySupport: {
    title: messages.companySupport,
    loadItems: () => getAllStaticPages()
  },
  carParts: {
    title: messages.carParts,
    loadItems: () => getCategories()
  },
  blog: {
    title: messages.blog,
    link: `/webview?uri=${WEBSITE_URL}/blog&title=Blog`
  },
  helpCenter: {
    title: messages.helpCenter,
    link: `/webview?uri=${WEBSITE_URL}/help-center&title=Help Center`
  },
  inventory: {
    title: messages.inventory,
    link: '/profile/inventory/available',
    icon: <ListingIcon />,
    role: USER_ROLE.BUSINESS
  }
} as const

export const generalMenu: MenuItem[] = [
  ...(isWeb ? [] : [menuItems.youtube, menuItems.blog, menuItems.helpCenter]),
  menuItems.contactUs,
  ...(isWeb ? [] : [menuItems.companySupport])
]

export const profileMenu: MenuItem[] = [
  menuItems.profileDetails,
  menuItems.myGarage,
  menuItems.messages
]

export const sellingMenu: MenuItem[] = [
  menuItems.myListings,
  menuItems.inventory,
  menuItems.soldItems,
  menuItems.offers,
  menuItems.auctions,
  menuItems.shipping
]

export const buyingMenu: MenuItem[] = [
  menuItems.myOrders,
  menuItems.myOffers,
  menuItems.myBids,
  menuItems.auctionsWatchlist,
  menuItems.matchSearch
]

export const SEARCH_LINK = isWeb ? '/' : '/search'
