export enum USER_STATUS {
  verified = 'verified',
  blocked = 'blocked',
  deleted = 'deleted',
  guest = 'guest'
}

export enum SHIPPING_STATUS_TYPE {
  ship_only = 'ship_only',
  click_and_collect = 'click_and_collect',
  both = 'both'
}

export enum ORDER_STATUS {
  partially_paid = 'partially_paid',
  create_shipping = 'create_shipping',
  completed = 'completed',
  fully_paid = 'fully_paid',
  processing = 'processing'
}

export enum LISTING_STATUS {
  available = 'available',
  draft = 'draft',
  sold_out = 'sold_out',
  seller_on_holiday_mode = 'seller_on_holiday_mode',
  reserved = 'reserved',
  blocked = 'blocked'
}

export enum LISTING_RESERVED_STATUS {
  reserve_listings_quantity = 'reserve_listings_quantity',
  return_listings_quantity = 'return_listings_quantity'
}

export enum ORDERS_TABS {
  orders = 'orders',
  refunds = 'refunds'
}

export enum STORE_TABS {
  products = 'products',
  reviews = 'reviews'
}

export const LISTING_STEPS = {
  general: 'general',
  media: 'media',
  shipping: 'shipping',
  returns: 'returns',
  pricing: 'pricing'
}

export const LISTING_QUERY_STATUS = {
  available: [LISTING_STATUS.available, LISTING_STATUS.blocked],
  draft: [LISTING_STATUS.draft],
  sold_out: [LISTING_STATUS.sold_out],
  seller_on_holiday_mode: [
    LISTING_STATUS.available,
    LISTING_STATUS.seller_on_holiday_mode
  ]
}

export const INVENTORY_QUERY_STATUS = {
  available_and_sold_out: [
    LISTING_STATUS.available,
    LISTING_STATUS.sold_out,
    LISTING_STATUS.blocked
  ],
  available: [LISTING_STATUS.available],
  draft: [LISTING_STATUS.draft],
  sold_out: [LISTING_STATUS.sold_out],
  seller_on_holiday_mode: [
    LISTING_STATUS.available,
    LISTING_STATUS.sold_out,
    LISTING_STATUS.seller_on_holiday_mode
  ],
  blocked: [LISTING_STATUS.blocked]
}

export const OFFER_STATUS = {
  accept: 'accept',
  pending: 'pending',
  decline: 'decline',
  paid: 'paid',
  expired: 'expired'
}

export const ACTION_STATUS = {
  buyer: 'buyer',
  seller: 'seller'
}

export const AUCTION_STATUS = {
  open: 'open',
  closed: 'closed'
}

export const LISTING_BUY_TYPES = {
  auction: 'auction',
  offer: 'offer',
  listing: 'listing'
}

export const BID_STATUS = {
  pending: 'pending',
  winner: 'winner',
  notPaid: 'not_paid'
}

export const BID_READ_STATUS = {
  unread: 'unread',
  read: 'read'
}

export enum REFUND_STATUS {
  pending = 'pending',
  accepted = 'accepted',
  declined = 'declined',
  confirmed = 'confirmed'
}

export enum REFUND_DECISIONS {
  accept = 'accept',
  decline = 'decline',
  confirm = 'confirm'
}

export enum REPORT_TYPE {
  listing = 'listing',
  user = 'user'
}

export enum MATCH_SEARCH_TABS {
  match = 'match',
  search = 'search'
}

export enum REVIEWS_VARIANT {
  buyer = 'buyer',
  seller = 'seller'
}

export enum REVIEWS_TYPE {
  buyer_reviews = 'buyer_reviews',
  seller_reviews = 'seller_reviews'
}

export const REVIEWS_PARAMS_TYPE = {
  buyer: REVIEWS_TYPE.buyer_reviews,
  seller: REVIEWS_TYPE.seller_reviews
}

export const LISTING_ACTUAL_STATUS = {
  draft: 'draft',
  new: 'new'
} as const

export enum PAYMENT_CARDS_STATUS {
  stripe = 'stripe',
  paypal = 'paypal',
  both = 'both'
}

export const ORDERS_ACTION_PAGE = {
  checkout: 'checkout',
  payment: 'payment'
}

export enum STATIC_PAGE_VISIBILITY {
  both = 'both',
  marketplace = 'marketplace',
  landing = 'landing'
}

export enum COLUMN_TYPE {
  actions = 'actions',
  image = 'image',
  status = 'status',
  category = 'category',
  subcategory = 'subcategory',
  make = 'make',
  model = 'model',
  part_number = 'part_number',
  generation = 'generation',
  series = 'series',
  trim = 'trim',
  condition = 'condition',
  warranty = 'warranty',
  item_type = 'item_type',
  shipping_type = 'shipping_type',
  collection_address = 'collection_address',
  dispatch_address = 'dispatch_address',
  shipping_service = 'shipping_service',
  dimensions = 'dimensions',
  weight = 'weight',
  returns = 'returns',
  international_shipping = 'international_shipping',
  pricing_type = 'pricing_type',
  starting_price = 'starting_price',
  item_price = 'item_price',
  min_offer_price = 'min_offer_price',
  auction_duration = 'auction_duration'
}

export enum PATH_MY_LISTINGS_BY_ROLE {
  personal = '/profile/my-listings',
  business = '/profile/inventory'
}

export const REFUND_PAYMENT_STATUS = {
  inProgress: 'in_progress',
  failed: 'failed',
  succeeded: 'succeeded'
} as const
