import { defineMessages } from 'react-intl'

export enum USER_ROLE {
  BUSINESS = 'business',
  PERSONAL = 'personal'
}

export const PAYPAL_PAYMENT_TYPE = {
  business: 'business',
  personal: 'personal'
} as const

type PaypalPaymentTypeKeys = keyof typeof PAYPAL_PAYMENT_TYPE

export type PaypalPaymentType =
  typeof PAYPAL_PAYMENT_TYPE[PaypalPaymentTypeKeys]

const messages = defineMessages({
  business: { defaultMessage: 'Business' },
  personal: { defaultMessage: 'Personal' }
})

export const userRoleText = {
  [USER_ROLE.BUSINESS]: messages.business,
  [USER_ROLE.PERSONAL]: messages.personal
}
