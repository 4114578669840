import React, { useEffect } from 'react'

import toast, {
  Toast,
  Toaster as MyToaster,
  useToasterStore
} from 'react-hot-toast'

import { Theme, useMediaQuery } from '@mui/material'

const TOAST_LIMIT = 5

const Toaster: RFC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { toasts } = useToasterStore()

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((t: Toast & ToastType) => t.variant === 'notify')
      .filter((_, i) => i >= TOAST_LIMIT)
      .forEach((t) => toast.dismiss(t.id))
  }, [toasts])

  return (
    <MyToaster
      position={isMobile ? 'top-center' : 'top-left'}
      reverseOrder={false}
    />
  )
}

export default Toaster
