export const chatKeys = {
  chat: ['chat'] as const,
  getTabChats: (params?: ChatTabDTO) => [
    ...chatKeys.chat,
    'getTabChats',
    params
  ],
  showAllChatMessages: () => [...chatKeys.chat, 'showAllChatMessages'],
  createChat: () => [...chatKeys.chat, 'createChat'],
  deleteChat: () => [...chatKeys.chat, 'deleteChat'],
  changingChatArchive: () => [...chatKeys.chat, 'changingChatArchive'],
  createChatMessage: () => [...chatKeys.chat, 'createChatMessage'],
  getChatNotifications: () => [...chatKeys.chat, 'getChatNotifications']
}
