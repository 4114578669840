export const matchMeKey = {
  matchMe: ['matchMe'] as const,
  createMatchMe: () => [...matchMeKey.matchMe, 'createMatchMe'],
  getMatchMe: (params) => [...matchMeKey.matchMe, 'getMatchMe', params],
  deleteMatchMe: () => [...matchMeKey.matchMe, 'deleteMatchMe'],
  matchMeToSavedSearchMutation: () => [
    ...matchMeKey.matchMe,
    'matchMeToSavedSearchMutation'
  ]
}
