export const auctionKeys = {
  auction: ['auction'] as const,
  placeBid: () => [...auctionKeys.auction, 'placeBid'],
  setAutoBid: () => [...auctionKeys.auction, 'setAutoBid'],
  deleteAutoBid: () => [...auctionKeys.auction, 'deleteAutoBid'],
  getLastBids: (auctionId: number) => [
    ...auctionKeys.auction,
    'getLastBids',
    auctionId
  ],
  getBuyerAuctions: (params?: PaginationParams) => [
    ...auctionKeys.auction,
    'getBuyerAuctions',
    params
  ],
  getSellerAuctions: (params?: PaginationParams) => [
    ...auctionKeys.auction,
    'getSellerAuctions',
    params
  ],
  getBuyerBids: (params: BuyerBidsParams) => [
    ...auctionKeys.auction,
    'getBuyerBids',
    params
  ],
  getSellerBids: (params: SellerBidsParams) => [
    ...auctionKeys.auction,
    'getSellerBids',
    params
  ],
  readBids: () => [...auctionKeys.auction, 'readBids'],
  getAuctionWatchLists: (params?: PaginationParams) => [
    ...auctionKeys.auction,
    'getAuctionWatchLists',
    params
  ],
  addAuctionToWatchList: () => [
    ...auctionKeys.auction,
    'addAuctionToWatchList'
  ],
  deleteAuctionFromWatchList: () => [
    ...auctionKeys.auction,
    'deleteAuctionFromWatchList'
  ]
}
