import fonts from './fonts'

export const typography = {
  h1: {
    fontFamily: fonts.nunito.name,
    fontWeight: 400,
    fontSize: 48,
    lineHeight: '65px'
  },
  h2: {
    fontFamily: fonts.nunito.name,
    fontWeight: 400,
    fontSize: 30,
    lineHeight: '35px'
  },
  h3: {
    fontFamily: fonts.poppins.name,
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '24px'
  },
  h4: {
    fontFamily: fonts.nunito.name,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '25px'
  },
  h5: {
    fontFamily: fonts.poppins.name,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px'
  },
  h6: {
    fontFamily: fonts.poppins.name,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px'
  },
  body1: {
    fontFamily: fonts.poppins.name,
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '24px'
  },
  body2: {
    fontFamily: fonts.poppins.name,
    fontWeight: 300,
    fontSize: 15,
    lineHeight: '22px'
  },
  subtitle1: {
    fontFamily: fonts.poppins.name,
    fontWeight: 300,
    fontSize: 14,
    lineHeight: '20px'
  },
  subtitle2: {
    fontFamily: fonts.poppins.name,
    fontWeight: 300,
    fontSize: 12,
    lineHeight: '18px'
  }
}

export type Typography = typeof typography

export default typography
