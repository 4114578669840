import React, { Ref, forwardRef, memo } from 'react'

import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/material'

export type BoxProps = MuiBoxProps

const Box = ({ children, ...props }: BoxProps, ref: Ref<unknown>) => {
  return (
    <MuiBox ref={ref} {...props}>
      {children}
    </MuiBox>
  )
}

export default memo(forwardRef(Box))
