import breakpoints from './breakpoints'
import fonts from './fonts'
import palette from './palette'
import typography from './typography'

const commonTheme = {
  spacing: (factor: number) => factor * 8,
  borderRadius: 8,
  breakpoints,
  palette,
  typography,
  fonts
}

export type CommonTheme = typeof commonTheme

export default commonTheme
