type OpenLinkProps = {
  link: string
  title?: string
  target?: '_blank' | '_self'
  withoutWebView?: boolean
}

const useOpenExternalLink = () => {
  const openLink = ({ link, target = '_blank' }: OpenLinkProps) => {
    setTimeout(() => {
      if (
        window.navigator &&
        window.navigator.userAgent.match(/(iPad|iPhone|iPod)/g)
      ) {
        window.open(link, '_self', 'noopener,noreferrer')
      } else {
        window.open(link, target, 'noopener,noreferrer')
      }
    }, 0)
  }

  return { openLink }
}

export default useOpenExternalLink
