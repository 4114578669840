import Api from 'app/services/Api'

/**
 * Get Static pages
 */
export const getAllStaticPages = (): Promise<StaticPages[]> =>
  Api.get('/api/v1/static_pages', { deserialize: true })

/**
 * Get static page by slug
 */
export const getStaticPageBySlug = (slug: string): Promise<StaticPage> =>
  Api.get(`/api/v1/static_pages/${slug}`, { deserialize: true })

/**
 * Get static page by id
 */
export const getAllSlugStaticPages = (): Promise<{ slugs: string[] }> =>
  Api.get(`/api/v1/sitemaps/static_page_slugs`)
