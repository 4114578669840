import NextApi from 'app/services/NextApi'

/**
 * Get Google Place Autocomplete
 */
export const nextPlaceAutocomplete = async (
  options: google.maps.places.AutocompletionRequest
): Promise<{
  predictions: google.maps.places.AutocompletePrediction[]
  status: google.maps.places.PlacesServiceStatus
}> => NextApi.get('/google/placeAutocomplete', { params: options })

/**
 * Get Google Place Details
 */
export const nextPlaceDetails = async ({
  placeId: placeid,
  ...rest
}: google.maps.places.PlaceDetailsRequest): Promise<{
  result: google.maps.places.PlaceResult
  status: google.maps.places.PlacesServiceStatus
}> => NextApi.get('/google/placeDetails', { params: { placeid, ...rest } })

/**
 * Get Google Geocode
 */
export const nextGeocode = async (
  options: unknown
): Promise<{
  status: google.maps.GeocoderStatus
  results: google.maps.GeocoderResult[]
}> => NextApi.get('/google/geocode', { params: options })
