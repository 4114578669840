import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  AxiosInstance
} from 'axios'

import Deserializer from 'app/services/Deserializer'

/**
 * Deep merges two objets.
 * @param  {Object} object destination object
 * @param  {Object} source source obejct
 *
 * @returns {Object} new object
 */
const merge = (object: Record<string, any>, source: Record<string, any>) => {
  if (object === source) return object

  const newValue = {
    ...object,
    ...source
  }

  Object.entries(source).forEach(([key, value]) => {
    if (object[key] && typeof object[key] === 'object') {
      newValue[key] = merge(object[key], value)
    } else {
      newValue[key] = value
    }
  })

  return newValue
}

export interface RequestOptions extends AxiosRequestConfig {
  deserialize?: boolean
  fullResponse?: boolean
  handleError?: boolean
}

type Handler = (url: string, config?: RequestOptions) => Promise<any>
type Handlers = {
  get: Handler
  post: Handler
  put: Handler
  patch: Handler
  del: Handler
}

class Axios {
  #config

  #instance: AxiosInstance
  interceptors: AxiosInstance['interceptors']

  constructor(config: AxiosRequestConfig) {
    this.#config = config
    this.#instance = axios.create(config)
    this.interceptors = this.#instance.interceptors
  }

  update(config: AxiosRequestConfig) {
    this.#instance = axios.create(merge(this.#config, config))
  }

  appendResponseErrorInterceptor = (interceptor) => {
    this.#instance.interceptors.response.use((r) => r, interceptor)
  }

  appendRequestErrorInterceptor = (interceptor) => {
    this.#instance.interceptors.request.use((r) => r, interceptor)
  }

  makeRequest(method: string, url: string, options: RequestOptions = {}) {
    const headers = {
      ...this.#instance.defaults.headers,
      ...options.headers
    } as any

    const { deserialize, fullResponse, ...axiosOptions } = options

    return this.#instance({
      ...axiosOptions,
      method,
      url,
      headers
    })
      .then((resp: AxiosResponse) => {
        let data = resp?.data

        if (deserialize) {
          data = Deserializer.deepDeserialize(data)
        }

        if (fullResponse) {
          return { ...resp, data }
        }

        return data
      })
      .catch(({ response }: AxiosError) => Promise.reject(response))
  }

  setAuthToken(auth_token?: string) {
    if (auth_token) {
      this.#instance.defaults.headers.common.Authorization = `Bearer ${auth_token}`
    } else delete this.#instance.defaults.headers.common.Authorization
  }

  get: Handler = (url, config) => this.makeRequest('get', url, config)

  post: Handler = (url, config) => this.makeRequest('post', url, config)

  put: Handler = (url, config) => this.makeRequest('put', url, config)

  patch: Handler = (url, config) => this.makeRequest('patch', url, config)

  del: Handler = (url, config) => this.makeRequest('delete', url, config)

  requestHandlers: Handlers = {
    get: this.get,
    post: this.post,
    put: this.put,
    patch: this.patch,
    del: this.del
  }
}

export default Axios
