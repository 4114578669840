import { useEffect } from 'react'

import useCurrencyState from '../useCurrencyState'
import useUserAddress from '../useUserAddress'

const useCurrencyByLocation = () => {
  const deviceAddress = useUserAddress()
  const { currency, changeCurrency } = useCurrencyState()

  const handleCurrencyByCountry = async () => {
    if (typeof localStorage !== 'undefined' && localStorage.currency) return

    if (!deviceAddress?.country) return

    const { countryCurrencies } = await import('./countryCurrencyConfig')
    const countyCode = deviceAddress.country
    const currencyByCountryCode: string = countryCurrencies[countyCode] ?? ''
    const currencyByCountry = currencyByCountryCode.toLowerCase()

    if (!!currencyByCountry && currencyByCountry !== currency) {
      changeCurrency(currencyByCountry)
    }
  }

  useEffect(() => {
    handleCurrencyByCountry()
  }, [deviceAddress?.country])
}

export default useCurrencyByLocation
