import { useCallback, useEffect, useRef, useState } from 'react'

import Geosuggest from 'app/services/Geosuggest'

type UseGeosuggestReturn = {
  geosuggest: Geosuggest | null
  loaded: boolean
  getGeosuggest: () => Promise<Geosuggest | null | undefined>
}

type UseGeosuggest = {
  loadOnMount?: boolean
}

const useGeosuggest = ({
  loadOnMount = false
}: UseGeosuggest = {}): UseGeosuggestReturn => {
  const loading = useRef(false)
  const [geosuggest, setGeoSuggest] = useState<Geosuggest | null>(null)

  const getGeosuggest = useCallback(async () => {
    if (geosuggest || loading.current) return geosuggest

    loading.current = true

    try {
      const instance = new Geosuggest()
      setGeoSuggest(instance)

      return instance
    } catch (error) {
      console.error(error)
    } finally {
      loading.current = false
    }
  }, [geosuggest])

  useEffect(() => {
    if (!loadOnMount || (loadOnMount && geosuggest)) return

    getGeosuggest()
  }, [loadOnMount, getGeosuggest])

  return { geosuggest, loaded: !!geosuggest, getGeosuggest }
}

export default useGeosuggest
