/* eslint-disable @typescript-eslint/no-this-alias */
import _extend from 'lodash/extend'
import _find from 'lodash/find'
import isFunction from 'lodash/isFunction'
import isPlainObject from 'lodash/isPlainObject'
import _transform from 'lodash/transform'
import { snakeCase } from 'snake-case'

export default class DeserializerUtils {
  jsonapi
  data
  opts
  alreadyIncluded = []

  constructor(jsonapi, data, opts) {
    this.jsonapi = jsonapi
    this.data = data
    this.opts = opts
  }

  perform() {
    const that = this

    function isComplexType(obj) {
      return Array.isArray(obj) || isPlainObject(obj)
    }

    function getValueForRelationship(relationshipData, included) {
      if (that.opts && relationshipData && that.opts[relationshipData.type]) {
        const valueForRelationshipFct =
          that.opts[relationshipData.type].valueForRelationship

        return valueForRelationshipFct(relationshipData, included)
      } else {
        return included
      }
    }

    function findIncluded(relationshipData, ancestry) {
      return new Promise(function (resolve) {
        if (!that.jsonapi.included || !relationshipData) {
          resolve(null)
        }

        const included = _find(that.jsonapi.included, {
          id: relationshipData.id,
          type: relationshipData.type
        })

        if (included) {
          // To prevent circular references, check if the record type
          // has already been processed in this thread
          if (ancestry.indexOf(included.type) > -1) {
            return Promise.all([extractAttributes(included)]).then(function (
              results: any
            ) {
              const attributes = results[0]
              const relationships = results[1]
              resolve(_extend(attributes, relationships))
            })
          }

          return Promise.all([
            extractAttributes(included),
            extractRelationships(
              included,
              ancestry + ':' + included.type + included.id
            )
          ]).then(function (results) {
            const attributes = results[0]
            const relationships = results[1]
            resolve(_extend(attributes, relationships))
          })
        } else {
          return resolve(null)
        }
      })
    }

    function keyForAttribute(attribute) {
      if (isPlainObject(attribute)) {
        return _transform(attribute, function (result: any, value, key) {
          if (isComplexType(value)) {
            result[keyForAttribute(key)] = keyForAttribute(value)
          } else {
            result[keyForAttribute(key)] = value
          }
        })
      } else if (Array.isArray(attribute)) {
        return attribute.map(function (attr) {
          if (isComplexType(attr)) {
            return keyForAttribute(attr)
          } else {
            return attr
          }
        })
      } else {
        if (isFunction(that.opts.keyForAttribute)) {
          return that.opts.keyForAttribute(attribute)
        } else {
          return snakeCase(attribute)
        }
      }
    }

    function extractAttributes(from) {
      const dest = keyForAttribute(from.attributes || {})
      if ('id' in from) {
        dest[that.opts.id || 'id'] = from.id
      }

      if (that.opts.typeAsAttribute) {
        if ('type' in from) {
          dest.type = from.type
        }
      }
      if ('meta' in from) {
        dest.meta = keyForAttribute(from.meta || {})
      }

      return dest
    }

    function extractRelationships(from, ancestry) {
      if (!from.relationships) {
        return
      }

      const dest = {}

      return Promise.all(
        Object.keys(from.relationships).map(function (key) {
          const relationship = from.relationships[key]

          if (relationship.data === null) {
            dest[keyForAttribute(key)] = null
          } else if (Array.isArray(relationship.data)) {
            return Promise.all(
              relationship.data.map(function (relationshipData) {
                return extractIncludes(relationshipData, ancestry)
              })
            ).then(function (includes) {
              if (includes) {
                dest[keyForAttribute(key)] = includes
              }
            })
          } else {
            return extractIncludes(relationship.data, ancestry).then(function (
              includes
            ) {
              if (includes) {
                dest[keyForAttribute(key)] = includes
              }
            })
          }
        })
      ).then(function () {
        return dest
      })
    }

    function extractIncludes(relationshipData, ancestry) {
      return findIncluded(relationshipData, ancestry).then(function (included) {
        const valueForRelationship = getValueForRelationship(
          relationshipData,
          included
        )

        if (valueForRelationship && isFunction(valueForRelationship.then)) {
          return valueForRelationship.then(function (value) {
            return value
          })
        } else {
          return valueForRelationship
        }
      })
    }

    return Promise.all([
      extractAttributes(that.data),
      extractRelationships(that.data, that.data.type + that.data.id)
    ]).then(function (results) {
      const attributes = results[0]
      const relationships = results[1]
      let record = _extend(attributes, relationships)

      // Links
      if (that.jsonapi.links) {
        record.links = that.jsonapi.links
      }

      // If option is present, transform record
      if (that.opts && that.opts.transform) {
        record = that.opts.transform(record)
      }

      return record
    })
  }
}
