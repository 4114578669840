import { useRouter } from 'next/router'
import { useEffect } from 'react'

import useAppStore from 'app/store/app.store'
import { rIdleCallback } from 'app/utils/helpers/functions.helpers'

import { LANDING_PATH } from '~/views/LandingView/helpers/landing.constants'

const useUserLocation = () => {
  const setLocation = useAppStore((state) => state.setLocation)
  const { asPath } = useRouter()
  const isLanding = asPath === LANDING_PATH

  useEffect(() => {
    rIdleCallback(() => {
      if (navigator.geolocation && !isLanding) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords
          setLocation({ latitude, longitude })
        })
      } else {
        console.error('Sorry Not available!')
      }
    })
  }, [])
}

export default useUserLocation
