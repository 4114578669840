import React, { useState } from 'react'

import { QueryClient, QueryClientProvider } from 'react-query'

type ReactQueryProviderProps = {
  children?: React.ReactNode
}

const ReactQueryProvider = ({ children }: ReactQueryProviderProps) => {
  const [queryClient] = useState(() => {
    // Create a client
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          retry: 0,
          refetchInterval: false,
          refetchOnWindowFocus: false
        }
      }
    })

    return queryClient
  })

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

export default ReactQueryProvider
