import type { TypographyOptions } from '@mui/material/styles/createTypography'

import fonts from '../common/fonts'
import commonTypography from '../common/typography'

const typography: TypographyOptions = {
  fontFamily: fonts.poppins.name,
  ...commonTypography
}

export default typography
