import { useQueryClient } from 'react-query'

import { auctionKeys } from 'app/services/Query/auction/auction.keys'
import { matchMeKey } from 'app/services/Query/match-me/match-me.keys'
import { notificationKeys } from 'app/services/Query/notification/notification.keys'
import { ordersKeys } from 'app/services/Query/orders/orders.keys'
import { userKeys } from 'app/services/Query/user/user.keys'
import { isOfType } from 'app/utils/helpers/type.helpers'

const TYPE_COUNTS = {
  unreadSoldItems: 'unread_sold_items',
  unreadBids: 'unread_bids',
  unreadAuctions: 'unread_auctions',
  unreadOutbids: 'unread_outbids',
  unreadBuyerReviews: 'unread_buyer_reviews',
  unreadSellerReviews: 'unread_seller_reviews',
  unreadMatchMe: 'unread_match_me'
} as const

const queryKeys = {
  [TYPE_COUNTS.unreadSoldItems]: ordersKeys.getSoldItems({}),
  [TYPE_COUNTS.unreadBids]: auctionKeys.auction,
  [TYPE_COUNTS.unreadAuctions]: auctionKeys.auction,
  [TYPE_COUNTS.unreadOutbids]: auctionKeys.auction,
  [TYPE_COUNTS.unreadBuyerReviews]: userKeys.user,
  [TYPE_COUNTS.unreadSellerReviews]: userKeys.user,
  [TYPE_COUNTS.unreadMatchMe]: matchMeKey.matchMe
}

const useInvalidateByChangeCount = () => {
  const queryClient = useQueryClient()

  const invalidateByChangeCount = ({
    nextCounts
  }: {
    nextCounts?: CounterNotificationsModel
  }) => {
    const prevCounts = queryClient.getQueryData(
      notificationKeys.getCounterNotifications()
    ) as CounterNotificationsModel

    const isCounts =
      !isOfType.undefined(prevCounts) && !isOfType.undefined(nextCounts)

    if (isCounts) {
      Object.values(TYPE_COUNTS).map((type) => {
        const prevCountSoldItem = prevCounts[type]
        const nextCountSoldItem = nextCounts[type]

        if (nextCountSoldItem > prevCountSoldItem) {
          queryClient.invalidateQueries(queryKeys[type])
        }
      })
    }
  }
  return { invalidateByChangeCount }
}

export default useInvalidateByChangeCount
