import { createTheme } from '@mui/material/styles'

import commonBreakpoints from '../common/breakpoints'
import commonTheme from '../common/theme'

import components from './components'
import palette from './palette'
import typography from './typography'

const theme = createTheme({
  spacing: commonTheme.spacing,
  breakpoints: {
    values: commonBreakpoints
  },
  shape: {
    borderRadius: commonTheme.borderRadius
  },
  palette,
  components,
  typography
})

export default theme
