import React from 'react'

import { Badge } from '@mui/material'

export type NotificationBadgeProps = {
  value?: string | number
  dataTestId?: string
}

const NotificationBadge: RFC<NotificationBadgeProps> = ({
  value,
  dataTestId
}) => {
  if (!value) {
    return null
  }

  return (
    <Badge
      color="primary"
      sx={{
        '& .MuiBadge-badge': {
          borderRadius: 10,
          fontSize: 12,
          color: 'primary.dark'
        }
      }}
      badgeContent={value}
      {...(!!dataTestId && {
        'data-testid': dataTestId
      })}
    />
  )
}

export default NotificationBadge
