import { useRouter } from 'next/router'

const useGetPayPalLoginParams = (): {
  authToken: string
  isNativeApp: string
} => {
  const router = useRouter()
  const query = router.query

  const authToken = (query?.authToken as string | undefined) ?? ''
  const isNativeApp = (query?.isNativeApp as string | undefined) ?? ''

  return {
    authToken,
    isNativeApp
  }
}

export default useGetPayPalLoginParams
