import React from 'react'

import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps
} from '@mui/material/IconButton'

export type IconButtonProps = MuiIconButtonProps & {
  onPress?: () => void
}

const IconButton = ({ children, onPress, ...props }: IconButtonProps) => {
  return (
    <MuiIconButton onClick={onPress} {...props}>
      {children}
    </MuiIconButton>
  )
}

export default IconButton
