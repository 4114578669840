import Storage from 'app/services/Storage'
import { AuthStore } from 'app/store/auth.store'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'

import { USER_STATUS } from '../constants/status.constants'
import { STORAGE_KEYS } from '../constants/storage.constants'

export const hasGuestStatus = (status?: UserStatus): boolean =>
  status === USER_STATUS.guest

interface InitializeUserWithTokenProps {
  methods: AuthStore['methods']
  actions: AuthStore['actions']
}

export const initializeUserWithToken = async ({
  methods,
  actions
}: InitializeUserWithTokenProps) => {
  try {
    const token = await Storage.getItem(STORAGE_KEYS.accessToken)
    const tokenData = await methods.getTokenData()

    if (tokenData?.keep_me_logged_in) {
      await actions.authorize(token)
    }
  } catch (error) {
    await actions.logout()
  }
}

export const getAcceptedCookies = () => {
  if (typeof localStorage !== 'undefined' && localStorage.cookies) {
    const localStorageCookies = localStorage.cookies
    const cookiesData = JSON.parse(localStorageCookies)

    return cookiesData?.cookies
  }

  return IS_NATIVE_APP
}
