import Api from 'app/services/Api'

/**
 * Get chats
 */
export const getTabChats = (
  data: ChatTabDTO
): Promise<PaginationChatTabModel> =>
  Api.get(`/api/v1/users/chats`, {
    params: data,
    deserialize: true
  })

/**
 * Mark as read all chat messages
 */
export const markAsReadChatMessages = (chatId: string): Promise<void> =>
  Api.get(`/api/v1/users/chats/${chatId}/mark_as_reads`)

/**
 * Show all chat messages
 */
export const showAllChatMessages = (chatId: string): Promise<MessageModel[]> =>
  Api.get(`/api/v1/users/chats/${chatId}/messages`, { deserialize: true })

/**
 * Create chat
 */
export const createChat = ({
  listingId,
  participantId
}: {
  listingId: string
  participantId?: string
}): Promise<ChatTabModel> =>
  Api.post(`/api/v1/users/chats`, {
    data: {
      chat: {
        listing_id: listingId,
        ...(participantId && { participant_id: Number(participantId) })
      }
    },
    deserialize: true
  })

/**
 * Create chat message
 */
export const createChatMessage = (
  chatId: string,
  text: string
): Promise<MessageModel> =>
  Api.post(`/api/v1/users/chats/${chatId}/messages`, {
    data: {
      message: { text }
    },
    deserialize: true
  })

/**
 * Get chat notifications
 */
export const getChatNotifications = (): Promise<MessageNotificationModel> =>
  Api.get(`/api/v1/users/chats/notifications`, {
    deserialize: true
  })

/**
 * Changing chat archive status
 */
export const changingChatArchive = (chatId: string): Promise<void> =>
  Api.put(`/api/v1/users/archiving_chats/${chatId}`, {
    deserialize: true
  })

/**
 * Delete chat
 */
export const deleteChat = (chatId: string): Promise<void> =>
  Api.put(`/api/v1/users/chats/${chatId}`, {
    deserialize: true
  })
