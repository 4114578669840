import { useEffect, useMemo } from 'react'

import { shallow } from 'zustand/shallow'

import { useGetCurrencies } from 'app/services/Query/user/user.query'
import useAppStore from 'app/store/app.store'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import {
  defaultCurrencyItem,
  defaultCurrency
} from 'app/utils/constants/intl.constants'
import { rIdleCallback } from 'app/utils/helpers/functions.helpers'

const storeSelector = (state) => [state.currency, state.changeCurrency]

const useCurrencyState = () => {
  const [currency, changeCurrency] = useAppStore(storeSelector, shallow)

  const {
    data: currencies,
    isFetching,
    isFetched,
    refetch
  } = useGetCurrencies({
    options: { staleTime: Infinity, enabled: IS_NATIVE_APP }
  })

  useEffect(() => {
    if (!IS_NATIVE_APP && !isFetched) {
      rIdleCallback(() => {
        refetch()
      })
    }
  }, [])

  const currenciesItems = useMemo(
    () =>
      currencies?.map((item) => ({
        value: item.name,
        title: `${item.mark} - ${item.name?.toUpperCase()}`
      })),
    [currencies]
  )

  const currenciesObj = useMemo(() => {
    if (currencies) {
      return currencies.reduce(
        (acc, item) => ({ ...acc, [item.name]: item.mark }),
        defaultCurrencyItem
      )
    }

    return defaultCurrencyItem
  }, [currencies])

  const sign =
    currencies?.find((item) => item.name === currency)?.mark ||
    defaultCurrencyItem[defaultCurrency]

  return {
    sign,
    currency,
    changeCurrency,
    currenciesItems,
    currencies: currenciesObj,
    isLoading: isFetching
  }
}

export default useCurrencyState
