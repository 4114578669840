import { Platform } from 'react-native'

const isBrowser = () => ![typeof window, typeof document].includes('undefined')

export const IS_SSR =
  Platform.OS !== 'ios' && Platform.OS !== 'android' && Platform.OS !== 'web'
export const IS_BROWSER = typeof window !== 'undefined'
export const IS_NATIVE_APP = Platform.OS !== 'web'
export const IS_IOS_APP = Platform.OS === 'ios'
export const IS_ANDROID = Platform.OS === 'android'

export const IS_SAFARI =
  IS_BROWSER &&
  navigator?.vendor &&
  navigator?.vendor.indexOf('Apple') > -1 &&
  navigator?.userAgent &&
  navigator?.userAgent.indexOf('CriOS') === -1 &&
  navigator?.userAgent.indexOf('FxiOS') === -1

export const API_URL =
  process.env.NEXT_PUBLIC_API || process.env.EXPO_PUBLIC_API
export const WEBSITE_URL =
  process.env.NEXT_PUBLIC_WEBSITE || process.env.EXPO_PUBLIC_WEBSITE
export const GOOGLE_API_KEY =
  process.env.EXPO_GOOGLE_API_KEY || process.env.GOOGLE_API_KEY
export const WEBSOCKET_CABLE_URL =
  process.env.WEBSOCKET_CABLE_URL || process.env.EXPO_WEBSOCKET_CABLE_URL
export const STRIPE_API_KEY =
  process.env.STRIPE_API_KEY || process.env.EXPO_STRIPE_API_KEY || ''

export const ELASTIC_SEARCH_KEY =
  process.env.ELASTIC_SEARCH_KEY || process.env.EXPO_ELASTIC_SEARCH_KEY || ''
export const ELASTIC_SEARCH_API =
  process.env.ELASTIC_SEARCH_API || process.env.EXPO_ELASTIC_SEARCH_API || ''
export const ELASTIC_DEVELOPER_NAMESPACE =
  process.env.ELASTIC_DEVELOPER_NAMESPACE ||
  process.env.EXPO_ELASTIC_DEVELOPER_NAMESPACE ||
  ''

export const RECAPTCHA_GOOGLE_V3_SITE_KEY =
  process.env.RECAPTCHA_GOOGLE_V3_SITE_KEY || ''
export const JWT_CAPTCHA_SECRET_KEY = process.env.JWT_CAPTCHA_SECRET_KEY || ''

export const CI_ENV: 'staging' | 'production' | string =
  process.env.CI_ENV || process.env.EXPO_CI_ENV || ''

export const IS_RAF = IS_BROWSER && !!window.requestAnimationFrame
