import { useEffect } from 'react'

import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query'
import { createParam } from 'solito'

import useDebounceValue from 'app/hooks/useDebounceValue'
import Actioncable from 'app/services/Actioncable'
import {
  getChatNotifications,
  getTabChats,
  markAsReadChatMessages,
  showAllChatMessages
} from 'app/services/Api/chat'
import Deserializer from 'app/services/Deserializer'
import useAuthStore, { getAuthUserId } from 'app/store/auth.store'
import { omitEmptyValues } from 'app/utils/helpers/form.helpers'

import { chatKeys } from './chat.keys'
import { hasChannelUnsubscribe } from 'app/utils/helpers/type.helpers'

const sortByUpdateAt = (a, b) =>
  new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()

const { useParam } = createParam()

export const chatConfig = {
  getTabChats: {
    key: chatKeys.getTabChats,
    request: async (queryParams) => {
      const { queryKey, pageParam } = queryParams

      const [_key, _keyTab, params] = queryKey

      if (pageParam === false) {
        return { chats: [], pagy: {} }
      }

      const paramsValue = {
        ...params,
        ...(pageParam && { page: pageParam })
      }
      const normalizedParams = omitEmptyValues(paramsValue) as ChatTabDTO

      const resp = await getTabChats(normalizedParams)
      const chats = await Deserializer.deserialize(resp.chats)

      return { chats, pagy: resp.pagy }
    }
  },
  showAllChatMessages: {
    key: chatKeys.showAllChatMessages(),
    request: async ({ queryKey }) => {
      const [_key, chatId] = queryKey

      const resp = await showAllChatMessages(chatId)

      return resp
    }
  },
  getChatNotifications: {
    key: chatKeys.getChatNotifications(),
    request: getChatNotifications
  }
}

interface UseGetTabChatsProps {
  params: ChatTabDTO
  options?: UseInfiniteQueryOptions<PaginationChatTabModel>
}

export const useGetTabChats = (props: UseGetTabChatsProps) => {
  const userId = useAuthStore(getAuthUserId)
  const [chatId] = useParam('chatId')

  const { params, options } = props
  const { getTabChats: config } = chatConfig

  const by_title = useDebounceValue(params.by_title, 500)

  const paramsDebounce = {
    ...params,
    by_title
  }

  const state = useInfiniteQuery<PaginationChatTabModel>(
    config.key(paramsDebounce),
    config.request,
    {
      enabled: !!userId,
      getNextPageParam: (lastGroup) => lastGroup.pagy.next || false,
      onSuccess: (data) => {
        const chats = data?.pages?.map((el) => el.chats).flat(1)

        const currentTab = chats?.find((item) => item?.id === chatId)

        if (currentTab && currentTab?.chat_info?.unread_messages_count > 0) {
          markAsReadChatMessages(String(chatId))
        }
      },
      cacheTime: 0,
      ...options
    }
  )

  const queryClient = useQueryClient()
  const isSubscribe = state.isFetched && !!state.data

  useEffect(() => {
    let channel: unknown = null

    if (isSubscribe && !channel) {
      channel = Actioncable.subscribe(
        { channel: 'ChatsChannel' },
        {
          received: async (data) => {
            const deserializeData = await Deserializer.deserialize(data)

            queryClient.setQueryData(
              config.key(params),
              // @ts-ignore
              (oldData: InfiniteData<PaginationChatTabModel>) => {
                if (oldData && !!oldData.pages[0]?.chats?.length) {
                  const flatOldData = oldData.pages
                    ?.map((el) => el.chats)
                    .flat(1)

                  const tab = oldData?.pages[0]?.chats?.findIndex(
                    (item) => item.id === deserializeData?.id
                  )

                  const hasInPages = flatOldData?.findIndex(
                    (item) => item.id === deserializeData?.id
                  )

                  const isSameStatus =
                    flatOldData[0]?.status === deserializeData?.status

                  if (tab !== -1 && isSameStatus) {
                    const cloneOldData = JSON.parse(JSON.stringify(oldData))

                    cloneOldData.pages[0].chats[tab] = deserializeData

                    cloneOldData.pages[0].chats.sort(sortByUpdateAt)

                    return cloneOldData
                  }

                  if (tab === -1 && hasInPages === -1 && isSameStatus) {
                    const cloneOldData = JSON.parse(JSON.stringify(oldData))

                    cloneOldData.pages[0].chats.push(deserializeData)

                    cloneOldData.pages[0].chats.sort(sortByUpdateAt)

                    return cloneOldData
                  }
                }

                return oldData
              }
            )
          }
        }
      ).then((c) => {
        channel = c
      })
    }

    return (): void => {
      if (hasChannelUnsubscribe(channel)) {
        channel?.unsubscribe()
      }
    }
  }, [isSubscribe])

  return state
}

export const UseMarkAsReadChatMessages = async (chatId: string) => {
  await markAsReadChatMessages(chatId)
}

interface UseShowAllChatMessagesProps {
  chatId?: string
  options?: UseQueryOptions<MessageModel[]>
}

export const useShowAllChatMessages = (props: UseShowAllChatMessagesProps) => {
  const userId = useAuthStore(getAuthUserId)

  const { chatId, options } = props
  const { showAllChatMessages: config } = chatConfig

  const state = useQuery<MessageModel[]>([config.key, chatId], config.request, {
    enabled: !!userId && !!chatId,
    ...options
  })

  const queryClient = useQueryClient()

  useEffect(() => {
    let channel: unknown = null

    if (chatId && !channel) {
      channel = Actioncable.subscribe(
        { channel: 'ChatRoomsChannel', chat_id: chatId },
        {
          received: async (data) => {
            const deserializeData = await Deserializer.deserialize(data)

            await queryClient.setQueryData(
              [config.key, chatId],
              (oldData?: MessageModel[]) => {
                if (!oldData) {
                  return [deserializeData]
                }

                return [...oldData, deserializeData]
              }
            )
          }
        }
      ).then((c) => {
        channel = c
      })

      state.refetch()
    }

    return (): void => {
      if (hasChannelUnsubscribe(channel)) channel?.unsubscribe()
    }
  }, [chatId])

  return state
}

export const useGetChatNotifications = () => {
  const userId = useAuthStore(getAuthUserId)

  const { getChatNotifications: config } = chatConfig

  const state = useQuery<MessageNotificationModel>(config.key, config.request, {
    enabled: !!userId
  })

  const queryClient = useQueryClient()
  const isSubscribe = state.isFetched && !!state.data

  useEffect(() => {
    let channel: unknown = null

    if (isSubscribe && !channel) {
      channel = Actioncable.subscribe(
        { channel: 'ChatsNotificationsChannel' },
        {
          received: async (data) => {
            queryClient.setQueryData(config.key, () => {
              return data
            })
          }
        }
      ).then((c) => {
        channel = c
      })
    }

    return (): void => {
      if (hasChannelUnsubscribe(channel)) {
        channel?.unsubscribe()
      }
    }
  }, [isSubscribe])

  return state
}
