export const palette = {
  common: {
    black: '#000',
    white: '#fff'
  },
  primary: {
    main: '#102262',
    dark: '#101340'
  },
  secondary: {
    main: '#00EA90',
    dark: '#00C98D',
    contrastText: '#101340',
    light: '#17D3FF',
    third: '#00BB73'
  },
  text: {
    primary: '#101340',
    secondary: '#87899F',
    third: '#585A79',
    light: '#212B36',
    blogTitle: '#0B0B0B'
  },
  success: {
    main: '#60A18E',
    light: '#E4F4F0'
  },
  stroke: {
    main: '#D6DCE9'
  },
  border: {
    main: '#D6DCE9',
    disabled: '#0000001E'
  },
  error: {
    main: '#FF4969'
  },
  background: {
    default: '#FFFFFF',
    disabled: '#F2F7F7',
    light: '#FAFBFD',
    lightGreen: '#E5F9F6',
    dark: '#F6F9FE',
    selected: '#E0E0E0',
    hover: '#283870',
    blog: '#F3FCFB'
  },
  gold: {
    primary: '#FAFF00',
    secondary: '#FFB800'
  }
}

export type Palette = typeof palette

export default palette
