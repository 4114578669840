import { ReactNode } from 'react'

import { useIdleTimer } from 'react-idle-timer'

import useAuthStore, { getAuthActions, getAuthUser } from 'app/store/auth.store'

interface TrackUserIdleProps {
  children: ReactNode
}

const BASE_TIMEOUT = 30 * 60 * 1000 // 30 min

const TrackUserIdle = ({ children }: TrackUserIdleProps) => {
  const user = useAuthStore(getAuthUser)
  const actions = useAuthStore(getAuthActions)

  const CHECK_SESSION_EXP_TIME = BASE_TIMEOUT
  const SESSION_IDLE_TIME = BASE_TIMEOUT

  const onAction = () => {
    if (!user?.id) return

    const serverExpires = user?.exp ?? 0
    const expiresTimeTimestamp = serverExpires * 1000
    const currentTimestamp = Date.now()
    const timeRemaining = expiresTimeTimestamp - currentTimestamp

    if (timeRemaining < CHECK_SESSION_EXP_TIME) {
      actions.updateSession()
    }
  }

  useIdleTimer({
    timeout: SESSION_IDLE_TIME,
    throttle: 1000,
    events: ['keydown', 'touchmove', 'mousedown', 'touchend'],
    onAction
  })

  return <>{children}</>
}

export default TrackUserIdle
