import { useQuery } from 'react-query'

import { getCounterNotifications } from 'app/services/Api/user'
import useAuthStore, { getAuthUser } from 'app/store/auth.store'
import { hasGuestStatus } from 'app/utils/helpers/user.helpers'

import { notificationKeys } from './notification.keys'

export const notificationsConfig = {
  getCounterNotifications: {
    key: notificationKeys.getCounterNotifications(),
    request: getCounterNotifications
  }
}

export const useGetCounterNotifications = () => {
  const user = useAuthStore(getAuthUser)
  const isGuestUser = hasGuestStatus(user?.status)
  const enabled = !!user?.id && !isGuestUser

  const { getCounterNotifications: config } = notificationsConfig

  const state = useQuery<CounterNotificationsModel>(
    config.key,
    config.request,
    {
      enabled: enabled,
      staleTime: Infinity
    }
  )

  return state
}
