import React, { useEffect } from 'react'

import { useQuery, UseQueryOptions } from 'react-query'

import useGetPayPalLoginParams from 'app/hooks/useGetPayPalLoginParams'
import useAuthStore, {
  getAuthActions,
  getAuthMethods,
  getAuthState,
  getIsConfirmed
} from 'app/store/auth.store'
import { IS_BROWSER, IS_NATIVE_APP } from 'app/utils/constants/env.constants'

import Api from '../Api'
import { userKeys } from '../Query/user/user.keys'

export interface AuthProviderProps {
  queryOptions?: UseQueryOptions
}

const AuthProvider = ({
  children,
  queryOptions
}: React.PropsWithChildren<AuthProviderProps>) => {
  const actions = useAuthStore(getAuthActions)
  const methods = useAuthStore(getAuthMethods)
  const authState = useAuthStore(getAuthState)

  // Logic for webview on app
  const { authToken, isNativeApp } = useGetPayPalLoginParams()
  const isAuthApp = authToken && isNativeApp
  const isAuth = useAuthStore(getIsConfirmed)

  const setInitialData = async () => {
    const token = await methods.getToken()
    const selectedToken = isAuthApp ? authToken : token

    Api.setAuthToken(selectedToken)

    return {
      ...((IS_BROWSER && (await methods.getTokenData(authToken))) || {})
    }
  }

  const { error, isLoading, isIdle, refetch, isFetched } = useQuery<any>({
    queryKey: userKeys.user,
    queryFn: () => actions.authorize(isAuthApp && authToken),
    refetchIntervalInBackground: false,
    retry: false,
    refetchInterval: false,
    initialData: setInitialData(),
    enabled: !IS_NATIVE_APP || isAuth || !!authToken,
    ...queryOptions
  })

  useEffect(() => {
    methods.updateStoreState({
      error,
      isLoading,
      isIdle,
      refetch,
      isFetched
    })
  }, [error, isLoading, isIdle, refetch, isFetched, authState.isFetched])

  return <>{children}</>
}

export default AuthProvider
