import { useEffect } from 'react'

import { shallow } from 'zustand/shallow'

import useGeosuggest from 'app/hooks/useGeosuggest'
import useAppStore from 'app/store/app.store'

const useUserAddress = () => {
  const { location, address, setAddress } = useAppStore(
    (state) => ({
      location: state.location,
      address: state.address,
      setAddress: state.setAddress
    }),
    shallow
  )

  const { getGeosuggest } = useGeosuggest()

  const getAddress = async (lat: number, lng: number) => {
    const geosuggest = await getGeosuggest()

    const resp = await geosuggest?.reverseGeocode({ lat, lng })
    setAddress(resp || null)
  }

  useEffect(() => {
    if (!!location.latitude && !!location.longitude && !address) {
      getAddress(location.latitude, location.longitude)
    }
  }, [location.latitude, location.longitude])

  return address
}

export default useUserAddress
