const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  cmd: 950,
  lg: 1200,
  xl: 1440,
  xxl: 1600
}

export type Breakpoints = typeof breakpoints

export default breakpoints
