import React from 'react'

import { CssBaseline } from '@mui/material'
import { ThemeProvider as MuiThemeProvider, Theme } from '@mui/material/styles'

import muiTheme from 'app/theme/mui'

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <MuiThemeProvider<Theme> theme={muiTheme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}

export default ThemeProvider
